import {Form, Input, Radio, Space, Button, message, Spin} from "antd";
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { FormattedMessage } from 'react-intl';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormChangePassword = ({}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [messageApi, contextHolder] = message.useMessage();

    const [spinning, setSpinning] = useState(false);

    const spinTitle = <FormattedMessage id="progress_cambiar_password" defaultMessage="Cambiando contraseña..." />;

    let infor_user = (sessionStorage.getItem('info_user'))? JSON.parse(sessionStorage.getItem('info_user')):null;

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    useEffect(() => {
        if(id !== infor_user.idusuario)
               navigate('/principal');   
        
    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };

    const cancelar = (values) => {
        navigate('/principal');
    };

    const onFinish = (values) => {
        setSpinning(true);
        Axios.post("ht/changePasswordUsser",
            {
              "idusuario": id,
              "contrasenna": values.contrasenna,
            },
            {
                headers: headers
              }
          ).then(response => {
             setSpinning(false);
             if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: <FormattedMessage id="message_save_information" defaultMessage="Se ha guardado la infomación correctamente." />,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
                navigate('/principal');
            }else{
                messageApi.open({
                    type: 'error',
                    cocontent: <FormattedMessage id="message_error_save_information" defaultMessage="Error al guardar la infomación." />,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                cocontent: <FormattedMessage id="message_error_save_information" defaultMessage="Error al guardar la infomación." />,
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);


        });

      };


      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            <h2 style={{textAlign:"center"}}><FormattedMessage id="menu_cambiar_password" defaultMessage="Cambiar mi contraseña" /></h2>
            {contextHolder}
             <Form
                form={form}
                autoComplete="on"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 10,
                  }}
                name="form_in_modal"
                initialValues={{
                    activo: 1,
                }}
            >
            
                 
                <Form.Item
                    name="contrasenna"
                    label={<FormattedMessage id="label_pass" defaultMessage='Contraseña' />}
                    rules={[
                        {
                            required: true,
                            message: 'Por favor debe escribir la contraseña!',
                        },
                        {min: 8, message: 'Debe introducir al menos 8 caracteres'}
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label={<FormattedMessage id="label_confirm_pass" defaultMessage='Confirmar Contraseña' />}
                    dependencies={['contrasenna']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor confirma la  contraseña!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('contrasenna') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Las contraseñas no coinciden!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                                <Space>
                                  
                                    <Button danger onClick={cancelar}>
                                        <FormattedMessage id="btn_cancelar" defaultMessage="Cancelar" />
                                      </Button>

                                    <Button type="primary"  htmlType="submit">
                                       <FormattedMessage id="btn_guardar" defaultMessage="Guardar" />
                                    </Button>
                                  
                                 
                                </Space>
                    </Form.Item>
                
            </Form>

        </div>
        </Spin>
       
    );
};

export default FormChangePassword;
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Select, Spin, Space, Row, Col } from 'antd';
import {Link, useNavigate} from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoPersonAdd, IoPersonSharp, IoReload} from "react-icons/io5";
import Search from "antd/es/input/Search";

const columns = [
    // {
    //     title: 'Id',
    //     dataIndex: 'idusuario',
    //
    // },
    // {
    //     title: 'Nombre de Usuario',
    //     dataIndex: 'nombreusuario',
    // },
    {
        title:<FormattedMessage id="tabla_usuario_columna_correo" defaultMessage='Correo' />,
        dataIndex: 'correo',
    },{
        title: <FormattedMessage id="tabla_usuario_columna_telefono" defaultMessage='Teléfono' />, 
        dataIndex: 'telefono',
    },{
        title: <FormattedMessage id="tabla_usuario_columna_rol" defaultMessage='Rol' />,
        dataIndex: 'rol',
    },{
        title: <FormattedMessage id="tabla_usuario_columna_registro" defaultMessage='Registro' />, 
        dataIndex: 'fecharegistro',
    },{
        title: <FormattedMessage id="tabla_usuario_columna_actualizado" defaultMessage='Actualizado' />,  
        dataIndex: 'fechaactualizacion',
    },{
        title: <FormattedMessage id="tabla_usuario_columna_activo" defaultMessage='Activo' />,  
        dataIndex: 'activo',
        render: (text) => (text==="1")?<IoPersonSharp size={20} color={'#0d6efd'}/>:<IoPersonSharp size={20} color={'red'}/>,
    }
];

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const { Option } = Select;

const Usuarios = () => {

    if(sessionStorage.getItem('user_mod'))
       sessionStorage.removeItem('user_mod');

    const [bottom, setBottom] = useState('bottomCenter');

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando usuarios...");


    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        recargar();
    }, []);  

    const recargar = () => {
        setSpinTitle("Cargando usuarios...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;


        Axios.post("ht/getUssers",
            {
                // "start": start,
                // "limit": limit,
                // "filtro":{
                //     "idrol": 1
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idusuario,
                        idusuario:response.data.data[i].idusuario,
                        // nombreusuario:response.data.data[i].nombreusuario,
                        correo:response.data.data[i].correo,
                        contrasenna:response.data.data[i].contrasenna,
                        activo:response.data.data[i].activo,
                        eliminado:response.data.data[i].eliminado,
                        fecharegistro:response.data.data[i].fecharegistro,
                        fechaactualizacion:response.data.data[i].fechaactualizacion,
                        idrol:response.data.data[i].idrol,
                        rol:response.data.data[i].rol,
                        telefono:response.data.data[i].telefono,
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        })

            .catch(error => {
                setSpinning(false);
            })
    };

    const getInfoForm = async (id) => {
        for(let i=0;i<data.length;i++){
            if(data[i].idusuario === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('user_mod', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar usuarios!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea eliminar los usuarios seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando usuarios");
                setSpinning(true);
                Axios.delete('ht/deleteUsser',
                    {
                      data: {
                        "idusuario": selectedRowKeys
                      },
                      headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar usuarios',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar usuarios',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);


                    });
            },
        });
    };

    function  activacion(e, activar){

        Modal.confirm({
            title: (activar)?'Activar usuario':'Desactivar usuario',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea {(activar)?'activar':'desactivar'} el usuario seleccionado?</span>
                </div>
            ),
            onOk() {
                setSpinTitle((activar)?'Activando usuarios...':'Desactivando usuarios...');
                setSpinning(true);
                Axios.post("ht/activarDesactivarUsser",
                     {
                            idusuario:selectedRowKeys[0],
                            activo:(activar)?1:0
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: (activar)?'Usuario activado satisfactoriamente':'Usuario desactivado satisfactoriamente',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: (activar)?'Error activando usuario':'Error desactivando usuario',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: (activar)?'Error activando usuario':'Error desactivando usuario',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);


                    });
            },
        });
    }

    const cambiarPass = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('user_mod', JSON.stringify(row));
    };


    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.correo.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idusuario === record.key){
                row = data[i];
            }
        }
       
        sessionStorage.setItem('user_mod', JSON.stringify(row));
        navigate(`/editusuarios/${record.key}`)
        
    };


    return (

        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div >
            <p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>
            {<FormattedMessage id="modulo_user_title" defaultMessage="Usuarios del Sistema" />}
                </p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 2,
                }}
            >
                {contextHolder}
                <Space direction='horizontal'>
                    <Link to="/addusuarios">
                        <Button type="primary" className="button" icon={<IoPersonAdd />} disabled={loading}>
                          {<FormattedMessage id="modulo_user_btn_add" defaultMessage="Adicionar" />}  
                        </Button>
                    </Link>
                    <Link to={`/editusuarios/${selectedRowKeys[0]}`}>
                        <Button type="primary" className="button" icon={<AiFillEdit />} onClick={modificar} disabled={loading || selectedRowKeys.length != 1}>
                        {<FormattedMessage id="modulo_user_btn_edit" defaultMessage="Modificar" />}   
                        </Button>
                    </Link>
                        <Button type="primary" danger className="button" icon={<AiFillDelete />} onClick={eliminar}
                                disabled={!hasSelected || loading}>
                           {<FormattedMessage id="modulo_user_btn_delete" defaultMessage="Eliminar" />}   
                        </Button>
                    <Link to={`/cambiarpass/${selectedRowKeys[0]}`}>
                        <Button type="primary" className="button" icon={<AiFillEdit />} onClick={cambiarPass} disabled={loading || selectedRowKeys.length !== 1}>
                        {<FormattedMessage id="modulo_user_btn_change_pass" defaultMessage="Cambiar contraseña" />}     
                            </Button>
                    </Link>

                    <Button type="primary" className="button" icon={<IoPersonSharp size={15} />} onClick={(e) => {
                        activacion(e, true);
                    }}
                        disabled={loading || selectedRowKeys.length !== 1}>
                       {<FormattedMessage id="btn_activar" defaultMessage="Activar" />} 
                    </Button>
                    <Button type="primary" danger className="button" icon={<IoPersonSharp size={15} />} onClick={(e) => {
                        activacion(e, false);
                    }}
                        disabled={loading || selectedRowKeys.length !== 1}>
                         {<FormattedMessage id="btn_desactivar" defaultMessage="Desactivar" />} 
                    </Button>
                    <Button type="primary" className="button" icon={<IoReload />} onClick={recargar} loading={loading || loading}>
                    {<FormattedMessage id="modulo_user_btn_recargar" defaultMessage="Recargar" />}  
                    </Button>

                    <Search
                        enterButton
                        placeholder="Correo"
                        style={{
                            width: 200,
                        }}
                        size="middle"
                        onSearch={onSearch}
                        onChange={(e) => {
                            filterLocal(e.target.value);
                        }}
                    />
                </Space>
            </div>

            {/*<Row gutter={[16, 16]}>*/}
            <Row>
                <Col span={24}>
                {/*<Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
                    <Table size="small"
                        // scroll={{
                        //     y: 530,
                        // }}
                        // scroll={{ y: 'calc(100vh - 100px)' }}
                        pagination={{
                            position: [bottom],
                            pageSize: 11,
                            // pagination: {
                            //     current: 1,
                            //     pageSize: 10,
                            // }
                        }}
                               //bordered={true}
                           rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       selectRowUpdate(record);
                                   }
                               }
                           }}/>
                </Col>
            </Row>

         </div>
        </Spin>
    );
};
export default Usuarios;
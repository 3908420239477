import {Checkbox, Form, Input, Space, Button, message, Spin, InputNumber, TimePicker, DatePicker, Row, Col, Card} from "antd";
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import moment from 'moment';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormViajeCorporativo = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [messageApi, contextHolder] = message.useMessage();

    const { TextArea } = Input;

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando datos...");

    const [title] = useState((accion === "mod")?"Modificar Viaje Corporativo":"Solicitar Viaje Corporativo");

    const [horaSalida, setHoraSalida] = useState([]);

    const [horaRegreso, setHoraRegreso] = useState([]);

    const [idusuarioSession, setIdusuarioSession] = useState([]);

    const [correoSession, setCorreoSession] = useState([]);

    const [telefonoSession, setTelefonoSession] = useState([]);

    const [horaLlegada, setHoraLlegada] = useState([]);

    const [flagHoraSalida, setFlagHoraSalida] = useState(false);

    const [flagHoraRegreso, setFlagHoraRegreso] = useState(false);

    const [idsolicitudviaje, setidsolicitudviaje] = useState([]);

    const [idCategoriaServicio, setIdCategoriaServicio] = useState([]);

    const [tipotransporte, setTipotransporte] = useState([]);

    const [estado, setEstado] = useState(true);

    const [componentDisabled, setComponentDisabled] = useState(true);

    const [loading, setLoading] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    dayjs.extend(customParseFormat);
    const onTimePickerSalidaChange = (time, timeString) => {
        setHoraSalida(timeString);
        setFlagHoraSalida(true);
    };

    const onTimePickerRegresoChange = (time, timeString) => {
        setHoraRegreso(timeString);
        setFlagHoraRegreso(true);
    };

    useEffect(() => {
        cargarTipoTransporte();

        let info_user = JSON.parse(sessionStorage.getItem('info_user'));
        setCorreoSession(info_user.correo);
        setTelefonoSession(info_user.telefono);
        setIdusuarioSession(info_user.idusuario);

        if(accion === "mod"){
            if(!sessionStorage.getItem('corp_mod')){
                navigate('/viajecorporativo');
            }else{
                let corp_mod = JSON.parse(sessionStorage.getItem('corp_mod'));

                //console.log('corp_mod.idtipotransporte', corp_mod);

                setidsolicitudviaje(corp_mod.idsolicitudviaje);
                setIdCategoriaServicio(corp_mod.idcategoriaservicio);
                setHoraSalida(corp_mod.horasalida);

                // let day = (corp_mod.fechaviaje.$D<10)?"0"+corp_mod.fechaviaje.$D:corp_mod.fechaviaje.$D;
                // let $M = corp_mod.fechaviaje.$M+1;
                // let month = ($M<10)?"0"+$M:$M;
                // let fecha = day+"/"+month+"/"+corp_mod.fechaviaje.$y;

                if (corp_mod.regresa === "1") {
                    setEstado(false);
                }else{
                    setEstado(true);
                    //si regresa viene en 0
                    // corp_mod.fecharegreso = false;
                    // corp_mod.horaregreso = false;
                }

                if(id !== corp_mod.idsolicitudviaje)
                   navigate('/editviajecorporativo');

                form.setFieldsValue({
                    "idsolicitudviaje": idsolicitudviaje,
                    "idcategoriaservicio": idCategoriaServicio, // Personalizado
                    "idviajecharterfecha": null,
                    "origen": corp_mod.origen,
                    "destino": corp_mod.destino,
                    "fechaviaje": dayjs(corp_mod.fechaviaje, 'DD/MM/YYYY'),
                    // "fechaviaje": fecha,
                    "horasalida": dayjs(corp_mod.horasalida, 'HH:mm'),
                    "correoregistro": correoSession,
                    "telefonousuariosolic": telefonoSession,
                    "tipotransporte": corp_mod.idtipotransporte,
                    "cantninnos": corp_mod.cantninnos,
                    "cantadultos": corp_mod.cantadultos,
                    "cantsillaruedas": corp_mod.cantsillaruedas,
                    "idusuariocrea": idusuarioSession,
                    "caracteristicaomnibus": corp_mod.caracteristicaomnibus.replace(/,/g, ' / '),
                    "fecharegreso": (corp_mod.fecharegreso)?dayjs(corp_mod.fecharegreso, 'DD/MM/YYYY'):'',
                    "horaregreso": (corp_mod.horaregreso)?dayjs(corp_mod.horaregreso, 'HH:mm'):'',
                    "regresa":(corp_mod.regresa === "1"),
                    });
            }
        }

    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };


    useEffect(() => {
    }, []);

    const cancelar = (values) => {
        navigate('/viajecorporativo');
    };

    const onSelectTipoTransporte = (value, tipotransporte) => {
        form.setFieldsValue({ caracteristicaomnibus: (tipotransporte.caracteristicaomnibus.replace(/"/g, '')).replace(/,/g, ' / ') });
    };

    const cargarTipoTransporte = () => {
        setLoading(true);
        let aux = [];
        let start = 0;
        let limit = 1000;
        Axios.get(`ht/getTipoTransporte?start=${start}&limit=${limit}`,{
            headers: headers
        })
            .then(response => {
                setLoading(false);
                if(response.data.data.length>0){
                    for(var i=0;i<response.data.data.length;i++){
                        aux.push({
                            id:response.data.data[i].idtipotransporte,
                            nombre:response.data.data[i].denominacion,
                            caracteristicaomnibus:response.data.data[i].caracteristicaomnibus,
                        });
                    }
                }
                setTipotransporte(aux);
            })
            .catch(error => {
                setLoading(false);
                setTipotransporte(aux);
                //console.log("Error",error);
            })
    };

    const onFinish = (values) => {
        setSpinning(true);

        //console.log('values', values);

        let day = (values.fechaviaje.$D<10)?"0"+values.fechaviaje.$D:values.fechaviaje.$D;
        let $M = values.fechaviaje.$M+1;
        let month = ($M<10)?"0"+$M:$M;
        let fecha = day+"/"+month+"/"+values.fechaviaje.$y;
        
        /* let dayR = (values.fecharegreso.$D<10)?"0"+values.fecharegreso.$D:values.fecharegreso.$D;
        let $MR = values.fecharegreso.$M+1;
        let monthR = ($MR<10)?"0"+$MR:$MR;
        let fechaRegreso = dayR+"/"+monthR+"/"+values.fecharegreso.$y; */

        let fechaRegreso = null;

        if (values.fecharegreso) {
            let dayR = (values.fecharegreso.$D<10)?"0"+values.fecharegreso.$D:values.fecharegreso.$D;
            let $MR = values.fecharegreso.$M+1;
            let monthR = ($MR<10)?"0"+$MR:$MR;
            fechaRegreso = dayR+"/"+monthR+"/"+values.fecharegreso.$y;
        }        

        if(accion === "add"){
            setSpinTitle('Guardando datos...');
            Axios.post("ht/solicitarViaje",
                    {
                      "idcategoriaservicio": 2, // Corporativo
                      "idviajecharterfecha": null,
                      "origen": values.origen,
                      "destino": values.destino,
                      "fechaviaje": fecha,
                      "horasalida": horaSalida,
                      "correoregistro": correoSession,
                      "telefonousuariosolic": telefonoSession,
                      "idtipotransporte": values.tipotransporte,
                      "cantninnos": values.cantninnos,
                      "cantadultos": values.cantadultos,
                      "cantsillaruedas": values.cantsillaruedas,
                      "idusuariocrea": idusuarioSession,
                      "regresa": (values.regresa)?1:0,
                      "fecharegreso": fechaRegreso,
                      "horaregreso": horaRegreso
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/viajecorporativo');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar los datos',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }else if(accion === "mod"){
            setSpinTitle('Modificando datos...');
            Axios.post("ht/updateSolicitudViaje",
            {
                "idsolicitudviaje": idsolicitudviaje,
                "idcategoriaservicio": idCategoriaServicio, // Personalizado
                "idviajecharterfecha": null,
                "origen": values.origen,
                "destino": values.destino,
                "fechaviaje": fecha,
                "horasalida": horaSalida,
                "correoregistro": correoSession,
                "telefonousuariosolic": telefonoSession,
                "idtipotransporte": values.tipotransporte,
                "cantninnos": values.cantninnos,
                "cantadultos": values.cantadultos,
                "cantsillaruedas": values.cantsillaruedas,
                "idusuariocrea": idusuarioSession,
                "regresa": (values.regresa)?1:0,
                "fecharegreso": (values.regresa)?fechaRegreso:'',
                "horaregreso": (values.regresa)?horaRegreso:''
            },
            {
                headers: headers
              }
        ).then(response => {
          setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

                navigate('/viajecorporativo');
            }else{
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al guardar los datos',
                duration: 0,
            });
            setTimeout(messageApi.destroy, 2500);
        });
        }

      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    const onChangeValue = (e) => {

        if(estado){
            /* form.setFieldsValue({
                fecharegreso: '',
                horaregreso: ''
            }); */
            setEstado(false);
        }else{
            form.setFieldsValue({
                fecharegreso: '',
                horaregreso: ''
            });
            setEstado(true);
        }

    };
    // //componentDisabled, setComponentDisabled

    const disabledEndDate = (current) => {
        return current && current < startDate;
    };

    const disabledStartDate = (current) => {
        // return current && current < endDate;
        return current && current < dayjs().endOf('day');
    };

    const onChange = (dates) => {
        if (dates && dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    };


    // const disabledDate = (current) => {
    //     // Can not select days before today and today
    //     return current && current < dayjs().endOf('day');
    // };
    // const onChange = (date, dateString) => {
    //     console.log(dateString);
    // };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            {/*<h2 style={{textAlign:"center"}}>{title}</h2>*/}
            {/*<p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>{title}</p>*/}
            {contextHolder}
                 <Card title={<h2 style={{textAlign:"center"}}>{title}</h2>} style={{ width: '70%', background:"#ffffff" }}>
                 <Form
                     form={form}
                     name="three_column_form"
                     labelCol={{ span: 12 }}
                     wrapperCol={{ span: 16 }}
                     onFinish={onFinish}
                     initialValues={{ cantadultos: 1, cantninnos: 0, cantsillaruedas: 0 }}
                 >
                     <Row gutter={24}>
                         <Col span={11}>
                             {/*columna 1*/}
                             <Form.Item
                                 name="origen"
                                 label="Origen"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar el Origen!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="fechaviaje"
                                 label="Fecha de viaje"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor seleccionar la fecha de viaje!',
                                     }
                                 ]}
                             >

                                 <DatePicker
                                     style={{width:'100%'}}
                                     disabledDate={disabledStartDate}
                                     // onChange={onChange}
                                     onChange={(date) => setStartDate(date)}
                                     format='DD/MM/YYYY'
                                 />

                             </Form.Item>

                             <Form.Item
                                 name="tipotransporte"
                                 label="Tipo de Transporte"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor seleccione el tipo de transporte del servicio!',
                                     },
                                 ]}>

                                 <GlobalSelect onSelect={onSelectTipoTransporte} loading={loading} valueName={'tipotransporte'} data={tipotransporte}/>

                             </Form.Item>

                             <Form.Item
                                 name="caracteristicaomnibus"
                                 label="Características"
                             >
                                 <TextArea
                                     readOnly
                                     rows={4}
                                     style={{
                                         height: 100,
                                         resize: 'none',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="regresa"
                                 label="regresa"
                                 valuePropName="checked"
                                 //initialValue={false}
                                 noStyle
                             >
                                 <Checkbox style={{ marginLeft: '90px' }} onChange={onChangeValue}><p style={{fontWeight:'bold'}}>Regresa</p></Checkbox>
                                 {/*<Checkbox style={{ marginLeft: '90px' }} onChange={(e) => setComponentDisabled( (e.target.checked)?false:true )}><p style={{fontWeight:'bold'}}>Regresa</p></Checkbox>*/}
                             </Form.Item>



                         </Col>
                         <Col span={11} >
                             {/*columna 2*/}
                             <Form.Item
                                 name="destino"
                                 label="Destino"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar el Destino!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="horasalida"
                                 label="Hora Salida"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar la Hora Salida!',
                                     },
                                 ]}
                             >
                                 <TimePicker
                                     onChange={onTimePickerSalidaChange}
                                     placeholder="Seleccione Hora Salida"
                                     //open={horaSalida}
                                     // onOpenChange={onTimePickerSalidaChange}
                                     format={'HH:mm'}
                                     defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="cantadultos"
                                 label="Cantidad de Adultos"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar la Cantidad de Adultos!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     min={1}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="cantninnos"
                                 label="Cantidad de Niños"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar la Cantidad de Niños!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     min={0}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>



                             <Form.Item
                                 name="cantsillaruedas"
                                 label="Cantidad de Sillas de Rueda"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar la Cantidad de Sillas de Rueda!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     min={0}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>



                         </Col>
                         {/*<Col span={8} >*/}
                         {/*    /!*columna 3*!/*/}



                         {/*</Col>*/}
                     </Row>

                     {/*<Row gutter={24}>*/}
                     {/*    <Col span={24}>*/}

                     {/*        <Form.Item*/}
                     {/*            name="regresa"*/}
                     {/*            label="regresa"*/}
                     {/*            // valuePropName="checked"*/}
                     {/*            noStyle*/}
                     {/*        >*/}
                     {/*            <Checkbox onChange={(e) => setComponentDisabled( (e.target.checked)?false:true )}><p style={{fontWeight:'bold'}}>Regresa</p></Checkbox>*/}
                     {/*            /!*<Checkbox onChange={onChangeValue}><p style={{fontWeight:'bold'}}>Regresa</p></Checkbox>*!/*/}
                     {/*        </Form.Item>*/}

                     {/*    </Col>*/}

                     {/*</Row>*/}

                     <Row gutter={24}>
                         <Col span={11}>
                             {/*columna 1*/}


                             <Form.Item
                                 name="fecharegreso"
                                 label="Fecha de Regreso"
                                 hidden={estado}
                                 rules={[
                                     {
                                         required: (estado)?false:true,
                                         // required: (componentDisabled)?false:true,
                                         message: 'Por favor seleccionar la fecha de regreso!',
                                     }
                                 ]}
                             >

                                 <DatePicker
                                     disabled={estado}
                                     style={{
                                         width:'100%',
                                         visibility: estado ? 'hidden' : 'visible'
                                     }}
                                     disabledDate={disabledEndDate}
                                     // onChange={onChange}
                                     onChange={(date) => setEndDate(date)}
                                     format='DD/MM/YYYY'
                                 />

                             </Form.Item>



                         </Col>
                         <Col span={11} >
                             {/*columna 2*/}

                             <Form.Item
                                 name="horaregreso"
                                 label="Hora Regreso"
                                 hidden={estado}
                                 rules={[
                                     {
                                         required: (estado)?false:true,
                                         // required: (componentDisabled)?false:true,
                                         message: 'Por favor debe insertar la Hora Regreso!',
                                     },
                                 ]}
                             >
                                 <TimePicker
                                     disabled={estado}
                                     onChange={onTimePickerRegresoChange}
                                     placeholder="Seleccione Hora Regreso"
                                     //open={horaSalida}
                                     // onOpenChange={onTimePickerRegresoChange}
                                     format={'HH:mm'}
                                     defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                     style={{
                                         width: '100%',
                                         visibility: estado ? 'hidden' : 'visible'
                                     }}
                                 />
                             </Form.Item>

                         </Col>
                     </Row>

                     <Form.Item wrapperCol={{ offset: 12, span: 16 }} {...tailLayout}>
                         <Space>

                             <Button danger onClick={cancelar}>
                                 Cancelar
                             </Button>
                             <Button type="primary"  htmlType="submit">
                                 Solicitar Cotización
                             </Button>

                         </Space>
                     </Form.Item>
                 </Form>
                 </Card>

        </div>
        </Spin>

    );
};

export default FormViajeCorporativo;
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Spin, Space, Row, Col, Tag} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoReload} from "react-icons/io5";
import Search from "antd/es/input/Search";

const columns = [
    // {
    //     title: 'Id',
    //     dataIndex: 'idtipotransporte',
    // },
    {
        title: 'Denominación',
        dataIndex: 'denominacion',
        width: 200
    },{
        title: 'Marca',
        dataIndex: 'marca',
        width: 200
    },{
        title: 'Características',
        dataIndex: 'caracteristicaomnibus',
        render: (tags) => (
            <span>
              {tags.map((tag) => {
                let color = 'geekblue';
                return (
                  <Tag color={color} key={tag}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
            </span>
          ),
    }/*,{
        title: 'Características',
        key: 'actions',
        render: (text, record) => (
            <Space size="middle">
                <Button icon={<AiFillDelete />}  />
                <Button icon={<AiFillEdit />}  />
            </Space>
        ),
    }*/
];

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

// const { Option } = Select;


const TipoTransporte = () => {

    if(sessionStorage.getItem('user_mod'))
       sessionStorage.removeItem('user_mod');

    const [bottom, setBottom] = useState('bottomCenter');

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando datos...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading/*, setLoading*/] = useState(false);

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        recargar();
    }, []);

    const recargar = () => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        let start = 0;
        let limit = 1000;

        Axios.get(`ht/getTipoTransporte?start=${start}&limit=${limit}`,
        {
            headers: headers
          })
            .then(response => {
                setSpinning(false);
                //console.log('CaractTransporte', response.data);
                if(response.data.data.length>0){
                    for(let i=0;i<response.data.data.length;i++){
                        aux.push({
                            key:response.data.data[i].idtipotransporte,
                            idtipotransporte:response.data.data[i].idtipotransporte,
                            denominacion:response.data.data[i].denominacion,
                            marca:response.data.data[i].marca,
                            idcaracteristicaomnibus:response.data.data[i].idcaracteristicaomnibus,
                            caracteristicaomnibus:response.data.data[i].caracteristicaomnibus.replace(/[ '"]+/g, ' ').split(','),
                        });
                    }
                }

                setData(aux);
                setOriginal(aux);
            })
            .catch(error => {
                setSpinning(false);
            })
    };

    const getInfoForm = async (id) => {
        for(let i=0; i < data.length; i++){
            if(data[i].idtipotransporte === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        // console.log(selectedRowKeys[0])
        sessionStorage.setItem('user_mod', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar datos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea eliminar los datos seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando datos");
                setSpinning(true);
                Axios.delete('ht/deleteTipoTransporte',
                    {
                      data: {
                        "idtipotransporte": selectedRowKeys
                      },
                      headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    });
            },
        });
    };

    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.denominacion.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idtipotransporte === record.key){
                row = data[i];
            }
        }

        sessionStorage.setItem('user_mod', JSON.stringify(row));
        navigate(`/edittipotransporte/${record.key}`)
        
    };


    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div>
            {/*<h2 style={{textAlign:"center"}}>Tipo de transporte</h2>*/}
            <p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>Tipo de transporte</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 10,
                }}
            >
                {contextHolder}
                <Space direction='horizontal'>
                    <Link to="/addtipotransporte">
                        <Button type="primary" className="button" icon={<MdAddHome />} disabled={loading}>
                            Adicionar
                        </Button>
                    </Link>
                    <Link to={`/edittipotransporte/${selectedRowKeys[0]}`}>
                        <Button type="primary" className="button" icon={<AiFillEdit />} onClick={modificar} disabled={loading || selectedRowKeys.length !== 1}>
                            Modificar
                        </Button>
                    </Link>
                        <Button type="primary" danger className="button" icon={<AiFillDelete />} onClick={eliminar}
                                disabled={!hasSelected || loading}>
                            Eliminar
                        </Button>

                    <Button type="primary" className="button" icon={<IoReload />} onClick={recargar} loading={loading}>
                        Recargar
                    </Button>

                    <Search
                        placeholder="Escriba la denominación"
                        enterButton
                        style={{
                            width: 250,
                        }}
                        size="middle"
                        onSearch={onSearch}
                        onChange={(e) => {
                            filterLocal(e.target.value);
                        }}
                    />
                </Space>
            </div>

            <Row>
                <Col span={24}>

                    <Table size="small"
                        // scroll={{
                        //     y: 400,
                        // }}
                        bordered={true}
                           pagination={{
                               position: [bottom],
                               pagination: {
                                   current: 1,
                                   pageSize: 10,

                               }}}
                           rowSelection={rowSelection}
                           columns={columns}
                           dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       selectRowUpdate(record);
                                   }
                               }
                           }}
                        // expandable={{
                        //     expandedRowRender: (record) => (
                        //         <p
                        //             style={{
                        //                 margin: 0,
                        //                 marginLeft: 85,
                        //                 color: '#174F60'
                        //             }}
                        //         >
                        //             <b>Características : </b>
                        //
                        //             {record.caracteristicaomnibus}
                        //         </p>
                        //
                        //
                        //     ),
                        //     rowExpandable: (record) => record.caracteristicaomnibus !== 'Not Expandable',
                        // }}
                    />

                </Col>
            </Row>



         </div>
        </Spin>
    );
};
export default TipoTransporte;
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Modal, Select, Spin, Space, Row, Col, Tag } from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoReload} from "react-icons/io5";
import { CarFilled } from '@ant-design/icons';
 
import Search from "antd/es/input/Search";
import _ from "lodash";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const { Option } = Select;

const Omnibus = () => {

    if(sessionStorage.getItem('omnibus_mod'))
       sessionStorage.removeItem('omnibus_mod');

    const [bottom, setBottom] = useState('bottomCenter');

    const navigate = useNavigate();

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };


    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando usuarios...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);
    const [loading/*, setLoading*/] = useState(false);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));
    
    
    const columns = [
        {
            title: 'Denominación',
            dataIndex: 'denominacion',
            filterSearch: true,
            width: '20%',
            filters: _.uniqWith(filterData(data)(i => i.denominacion), _.isEqual),
            onFilter: (value, record) => record.denominacion.startsWith(value),
        },{
            title: 'Tipo Transporte',
            dataIndex: 'tipotransporte',
            filterSearch: true,
            width: '15%',
            filters: _.uniqWith(filterData(data)(i => i.categoria), _.isEqual),
            onFilter: (value, record) => record.categoria.startsWith(value),
        },{
            title: 'Capacidad',
            dataIndex: 'capacidad',
            filterSearch: true,
            width: '10%',
            filters: _.uniqWith(filterData(data)(i => i.capacidad), _.isEqual),
            onFilter: (value, record) => record.capacidad.startsWith(value),
        },{
            title: 'Chofer',
            dataIndex: 'chofer',
            filterSearch: true,
            width: '15%',
            filters: _.uniqWith(filterData(data)(i => i.chofer), _.isEqual),
            onFilter: (value, record) => record.chofer.startsWith(value),
        },{
            title: 'Chapa',
            dataIndex: 'chapa',
            filterSearch: true,
            width: '10%',
            filters: _.uniqWith(filterData(data)(i => i.chapa), _.isEqual),
            onFilter: (value, record) => record.chapa.startsWith(value),
        },{
            title: 'Características',
            dataIndex: 'caracteristicaomnibus',
            render: (tags) => (
                <span>
                  {tags.map((tag) => {
                    let color = 'geekblue';
                    return (
                      <Tag color={color} key={tag}>
                        {tag.toUpperCase()}
                      </Tag>
                    );
                  })}
                </span>
              )
        }
    ];

    

    useEffect(() => {
        recargar();
    }, []);  

    const recargar = () => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        Axios.get('ht/getOmnibus',
        {
            headers: headers
          })
            .then(response => {
                setSpinning(false);
                if(response.data.data.length>0){
                    for(let i=0;i<response.data.data.length;i++){
                        aux.push({
                            key:response.data.data[i].idomnibus,
                            idomnibus:response.data.data[i].idomnibus,
                            denominacion:response.data.data[i].denominacion,
                            idtipotransporte:response.data.data[i].idtipotransporte,
                            capacidad:response.data.data[i].capacidad,
                            chofer:response.data.data[i].chofer,
                            idusuario:response.data.data[i].idusuario,
                            chapa:response.data.data[i].chapa,
                            tipotransporte:response.data.data[i].tipotransporte,
                            idcaracteristicaomnibus:response.data.data[i].idcaracteristicaomnibus,
                            caracteristicaomnibus:response.data.data[i].caracteristicaomnibus.replaceAll('"', '').split(',')
                        });
                    }
                }

                setData(aux);
                setOriginal(aux);
            })
            .catch(error => {
                setSpinning(false);
            })
    };

    const getInfoForm = async (id) => {
        for(let i=0;i<data.length;i++){
            if(data[i].idomnibus === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('omnibus_mod', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar datos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea eliminar los ómnibus seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando datos...");
                setSpinning(true);
                console.log(selectedRowKeys);
                Axios.delete('ht/deleteOmnibus',
                    {
                      data: {
                        "idomnibus": selectedRowKeys
                      },
                      headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: "Se han eliminado los datos correctamente.",
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });
            },
        });
    };


    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.denominacion.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        console.log('record', record);
        setSelectedRowKeys([record.key]);
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idomnibus === record.key){
                row = data[i];
            }
        }
       
        sessionStorage.setItem('omnibus_mod', JSON.stringify(row));
        navigate(`/editomnibus/${record.key}`)
        
    };


    return (

        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div >
            <p style={{textAlign:"center", margin:10, fontSize:'20px', fontWeight:'bold'}}>Listado de Vehículos</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 2,
                }}
            >
                {contextHolder}
                <Space direction='horizontal'>
                    <Link to="/addomnibus">
                        <Button type="primary" className="button" icon={<CarFilled />}>
                            Adicionar
                        </Button>
                    </Link>
                    <Link to={`/editomnibus/${selectedRowKeys[0]}`}>
                        <Button type="primary" className="button" icon={<AiFillEdit />} onClick={modificar} disabled={selectedRowKeys.length != 1}>
                            Modificar
                        </Button>
                    </Link>
                        <Button type="primary" danger className="button" icon={<AiFillDelete />} onClick={eliminar} disabled={!hasSelected}>
                            Eliminar
                        </Button>
                    {/*<Link to={`/revisiontecnica/${selectedRowKeys[0]}`}>*/}
                    {/*    <Button type="primary" className="button" icon={<AiFillEdit />} onClick={modificar} disabled={selectedRowKeys.length != 1}>*/}
                    {/*        Revisión Técnica*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}
                    <Button type="primary" className="button" icon={<IoReload />} onClick={recargar} loading={loading || loading}>
                        Recargar
                    </Button>

                    <Search
                        placeholder="Escriba la denominación"
                        enterButton
                        style={{
                            width: '100%',
                        }}
                        size="middle"
                        onSearch={onSearch}
                        onChange={(e) => {
                            filterLocal(e.target.value);
                        }}
                    />
                </Space>
            </div>

            <Row>
                <Col span={24}>
                    <Table size="small"
                          scroll={{
                              y: 300,
                          }}
                        pagination={{
                            position: [bottom],
                            pagination: {                                
                                current: 1,
                                pageSize: 10,
                            }}}
                            bordered={true}
                           rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       selectRowUpdate(record);
                                   }
                               }
                           }}/>
                </Col>
            </Row>

         </div>
        </Spin>
    );
};
export default Omnibus;
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Popconfirm, Form, Input, InputNumber, Checkbox, Typography, Table, Layout } from 'antd';
import {IoReload, IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber min={0} /> : inputType === 'check'?<Checkbox/>: <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        inputType === 'check'?
        <Form.Item
          name={dataIndex}
          valuePropName="checked"
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>:
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
 

const StepReservarPasajeros = ({setDisabled}) => { 

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const changeDisabled = (value) => {
    setDisabled(value)
  }


  const [cantadultos, setCantAdultos] = useState(0);

  const [cantninnos, setCantNinnos] = useState(0);

  const [cantsillaruedas, setCantSillaruedas] = useState(0);

  const [total, setTotal] = useState(0);


  const [data, setData] = useState([]);

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
        console.log('newData', newData);

        sessionStorage.setItem('data_pasajeros', JSON.stringify(newData));
       
        // Validar datos
        let error = false;
        let ninnos = 0;
        let adultos = 0;
        let sillarueda = 0;
        for(var i = 0; i<newData.length;i++){
          if(newData[i].nombre === ""){
            error = true;
            break;
          }

          if(newData[i].edad === 0){
            error = true;
            break;
          }
             
          ninnos += (newData[i].ninno) ? 1:0;    
          adultos += (!newData[i].ninno) ? 1:0;  
          sillarueda += (newData[i].sillarueda) ? 1:0;   
        }

        console.log('ninnos', ninnos);
        console.log('error', error);

        if(ninnos !== cantninnos)
           error = true;
        
        if(adultos !== cantadultos)
          error = true;

        if(sillarueda !== cantsillaruedas)
          error = true;

        changeDisabled(error);

      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  useEffect(() => {
    changeDisabled(true);
    if(!sessionStorage.getItem('data_distribucion') ||
          !sessionStorage.getItem('ruta') || 
          !sessionStorage.getItem('cantadultos')){
        navigate('/solicviajecharter');
    }else{
        if(sessionStorage.getItem('data_pasajeros'))
          sessionStorage.removeItem('data_pasajeros');   

        setCantAdultos(parseInt(sessionStorage.getItem('cantadultos')));
        setCantNinnos(parseInt(sessionStorage.getItem('cantninnos')));
        setCantSillaruedas(parseInt(sessionStorage.getItem('cantsillaruedas')));
        setTotal(parseInt(sessionStorage.getItem('cantadultos')) +
                     parseInt(sessionStorage.getItem('cantninnos')));

        let aux = [];
        let data_distribucion = JSON.parse(sessionStorage.getItem('data_distribucion'));
        console.log('data_distribucion',data_distribucion);
        let key = 1;
        for(var i = 0; i<data_distribucion.length;i++){
          for(var j = 0; j<parseInt(data_distribucion[i].distribucion);j++){
                aux.push({
                  key:key,
                  idviajevehiculo:data_distribucion[i].idviajevehiculo,
                  vehiculo:data_distribucion[i].denominacion,
                  nombre:"",
                  edad:0,
                  ninno:false,
                  sillarueda:false
                });
            key ++;
          }
        }

        setData(aux);
    }
   
  }, []);

  const defaultColumns = [
    {
      title: 'Vehículo',
      dataIndex: 'vehiculo',
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      editable: true,
    },
    {
      title: 'Edad',
      dataIndex: 'edad',
      editable: true,
    },
    {
        title: 'Niño',
        dataIndex: 'ninno',
        editable: true,
        render: (value) => (value)?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
    },
    {
      title: 'Silla de Rueda',
      dataIndex: 'sillarueda',
      editable: true,
      render: (value) => (value)?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
    },
    {
      title: 'Operación',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Salvar o cancelar?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'edad' ? 'number' : (col.dataIndex === 'ninno' || col.dataIndex === 'sillarueda')? 'check':'text',
        dataIndex: col.dataIndex,
        title: col.title,
        render:(col.dataIndex === 'ninno' || col.dataIndex === 'sillarueda')?col.render:null,
        editing: isEditing(record),
      }),
    };
  });

  const { Header, Content } = Layout;

  const headerStyle = {
    textAlign: 'center',
    color: '#000000',
    height: 64,
    paddingInline: 48,
    lineHeight: '64px',
    backgroundColor: '#ffff',
  };
  const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
  };

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
  };

  return (
          <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <span style={{fontWeight:'bold', color:'#FF0000'}}>Está planificando para Adultos: {cantadultos}, Niños: {cantninnos} y
                      Silla de ruedas: {cantsillaruedas}, con un total de {total} capacidad(es).  </span>
        </Header>
        <Content style={contentStyle}>
      <Form form={form} component={false}>
                <Table
                        size="small"
                        scroll={{
                            y: 300
                        }}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={data}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                    position: ["bottomCenter"],
                    pagination: {
                        current: 1,
                        pageSize: 10,
                    }
                  }}
                />
          </Form>
          </Content>
       </Layout>  
  );
};
export default StepReservarPasajeros;
import logowelcome from '../views/images/logowelcome.jpg'
  


const PageWelcome = ({ ...props }) => {
	return ( 
		<div>
		  <img src={logowelcome} alt="welcome" width="900" height="400"/> 
		</div>
    );
}
 
export default PageWelcome;
import {Checkbox, Form, Input, Modal, Radio, Select, Space, Button, Row, Col, message, Spin} from "antd";
import React, {useContext, useEffect, useState, contextHolder}  from "react";
import {Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormAddTipoRuta = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando información ...");

    const [title, setTitle] = useState((accion=="mod")?"Modificar Tipo de Ruta":"Adicionar Tipo de Ruta");

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    useEffect(() => {
        if(accion == "mod"){
            if(!sessionStorage.getItem('user_mod')){
                navigate('/tiporuta');
            }else{
                let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));

                // if(id !== user_mod.idcaracteristicaomnibus)
                //    navigate('/caracttransporte')

                form.setFieldsValue({
                    "denominacion": user_mod.denominacion,
                    "descripcion": user_mod.descripcion,
                    });
            }
               
        }

    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };


    const cancelar = (values) => {
        navigate('/tiporuta');
    }

    const onFinish = (values) => {
        // console.log('Success:', values);
        form.setFieldsValue({
            "denominacion": values.denominacion,
            "descripcion": values.descripcion,
            });
        setSpinning(true);
        if(accion == "add"){
            setSpinTitle('Guardando datos...');
            Axios.post("ht/addCategoriaServicio",
                    {
                      "denominacion": values.denominacion,
                      "descripcion": values.descripcion,
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/tiporuta');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar los datos',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }else if(accion == "mod"){
            setSpinTitle('Modificando datos...');
            Axios.post("ht/updateCategoriaServicio",
            {
              "idcategoriaservicio": id,
              "denominacion": values.denominacion,
              "descripcion": values.descripcion
            },
            {
                headers: headers
              }
        ).then(response => {
          setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

                navigate('/tiporuta');
            }else{
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al guardar los datos',
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);
        });
        }

      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            <h2 style={{textAlign:"center"}}>{title}</h2>
            {contextHolder}
             <Form
                form={form}
                autoComplete="on"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 10,
                  }}
                name="form_in_modal"
                initialValues={{
                    activo: 1,
                }}
            >
                <Form.Item
                    // hidden={(accion=="mod")}
                    name="denominacion"
                    label="Tipo de Ruta"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor debe poner el Tipo de Ruta!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                 <Form.Item
                     // hidden={(accion=="mod")}
                     name="descripcion"
                     label="Descripción"
                     rules={[
                         {
                             required: true,
                             message: 'Por favor debe poner la Descripción!',
                         },
                     ]}
                 >
                     <Input />
                 </Form.Item>


                <Form.Item {...tailLayout}>
                            <Space>

                                <Button danger onClick={cancelar}>
                                    Cancelar
                                  </Button>

                                <Button type="primary"  htmlType="submit">
                                    Guardar
                                </Button>


                            </Space>
                </Form.Item>
                
            </Form>

        </div>
        </Spin>
       
    );
};

export default FormAddTipoRuta;
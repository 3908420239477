import React, {useEffect, useContext, useState} from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,

} from '@ant-design/icons';
import {Layout, Menu, Button, theme, Image, Row, Col} from 'antd';
import {BrowserRouter} from "react-router-dom";
import {LoginContext} from "../components/seguridad/servicios/LoginServicios";
import UsuarioDropdown from "../generales/SessionDropdown";
import RoutersSystem from './Routers';
import MenuPrincipal from '../generales/MenuPricipal';

const { Header, Sider, Content } = Layout;


const Home = () => {

    const {logout} = useContext(LoginContext);

    if(!sessionStorage.getItem('idusuario') || 
             !sessionStorage.getItem('token') || 
                  !sessionStorage.getItem('info_user'))
        logout();

    
        let infor_user = (sessionStorage.getItem('info_user'))? JSON.parse(sessionStorage.getItem('info_user')):null;
                
       


    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {},
    } = theme.useToken();

    return (
        <BrowserRouter>
         <div style={{padding:"0", margin:"0", minHeight: '100vh', maxHeight: '100vh'}}>
                   <Layout style={{padding:"0", margin:"0", minHeight: '100vh', maxHeight: '100vh'}}>
                       <Sider trigger={null} collapsible collapsed={collapsed} >
                           <MenuPrincipal collapsed = {collapsed} />
                       </Sider>
                       <Layout >
                           <Header className="header-layout" style={{backgroundColor: '#3A90AB'}}>
                               <Row justify="space-between" align="middle" gutter={0}>
                                   <Row align="middle" style={{padding:0, height: 54}}>
                                       <Button
                                           type="text"
                                           icon={collapsed ? <MenuUnfoldOutlined color="#3A90AB"/> : <MenuFoldOutlined color="#3A90AB"/>}
                                           onClick={() => setCollapsed(!collapsed)}
                                           style={{
                                               fontSize: '25px',
                                               width: 64,
                                               height: 54,
                                               color:"white",
                                           }}
                                       >
                                           Alston York Travel
                                       </Button>
                                   </Row>
                                   <Row justify='end' align='middle' gutter={[10, 0]}>
                                       <Col>
                                           <UsuarioDropdown/>
                                       </Col>
                                   </Row>
                               </Row>

                           </Header>
                            <Content
                               style={{
                                   backgroundColor:'white',

                               }}
                           >
                            <RoutersSystem/>
                             
                           </Content>
                       </Layout>
                   </Layout>
               </div>
        </BrowserRouter>
              
    );
};
export default Home;

import React, {useContext} from 'react'
import FormLogin from "./views/FormLoginHT";
import Home from "./views/Home";
 
function App() {

        return (
            !sessionStorage.getItem('idusuario')?<FormLogin/>:<Home/>
        );
    }

    export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ConfigProvider } from 'antd';
import UserContextProvider from './components/seguridad/servicios/LoginServicios';
import reportWebVitals from './reportWebVitals';
import es_ES from 'antd/es/locale/es_ES';

import { IntlProvider } from 'react-intl';
import messages_en from "./translations/en.json";
 

const messages = {
    'en': messages_en,
};

// get browser language without the region code

let idioma = (sessionStorage.getItem('idioma'))?sessionStorage.getItem('idioma'):'es';

console.log('Lenguaje seleccionado', idioma);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider locale={es_ES}>
    <React.StrictMode>
        <UserContextProvider>
        <IntlProvider locale={idioma} messages={messages[idioma]}>
            <App />
        </IntlProvider>
        </UserContextProvider>
      </React.StrictMode>
  </ConfigProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

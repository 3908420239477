import React, {useEffect, useState} from 'react';
import { Button, Form, Input, Row, Card, Col, message, Select, Image} from 'antd';
import Logo from './images/logo.png';
import flags_us from './images/flags_us.png';
import flags_es from './images/flags_spanish.png';
import axios from "axios";
import useAutoFocus from "../generales/hooks/useAutoFocus";
import {LockOutlined, LoginOutlined, UserOutlined} from "@ant-design/icons";
import { FormattedMessage } from 'react-intl';

// const { Meta } = Card;

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormLoginHT = () => {

    const autoFocusInput = useAutoFocus();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const [token, setToken] = useState(null);

    const [headers, setHeaders] = useState(null);

    const [idioma, setIdioma] = useState('es');

    const validateEmail = (rule, value, callback) => {
        //const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
        const emailRegex = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(value)) {
            callback('Invalid email address');
        } else {
            callback();
        }
    };

    useEffect(() => {

        if(sessionStorage.getItem('idioma'))
           setIdioma(sessionStorage.getItem('idioma'));

        async function getToken() {
            Axios.post('/login',{
                "username": process.env.REACT_APP_USER,
                "password": process.env.REACT_APP_PASS
            }).then(response =>{
                let aux = response.data.token;
                sessionStorage.setItem('token', response.data.token);
                setToken(aux);
                let headerAux = {
                    'accept': '*/*',
                    'Authorization': 'Bearer ' + response.data.token,
                    'Content-Type': 'application/json',
                };
                setHeaders(headerAux);
            }).catch(error => {
                setToken(null);
                setHeaders(null);
                sessionStorage.removeItem('token');
            });
        }
        getToken();
    }, [])

    const permisosPorRol = (infor_user) =>{
        /*** Cargar menu por Roles 
    
               Rol 1 - Administrador
                   2 - Dispacher
                   3 - Chofer
                   4 - Usuario corporativo
                   5 - Usuario final
   
           *** */
       let funcionalidades = [];
       switch (infor_user.idrol) {
           case "1": // Administrador
                funcionalidades = ["menu_usuarios", "menu_transporte", "menu_viajes", "menu_rutas", "menu_rutas_tipo_rutas", "menu_solicitudes", "menu_solicitudes_corporativo", "menu_ofertas"];
                sessionStorage.setItem('funcionalidades', JSON.stringify(funcionalidades));
             break;
           case "2": // Dispacher
                funcionalidades = ["menu_viajes", "menu_rutas", "menu_ofertas"];
                sessionStorage.setItem('funcionalidades', JSON.stringify(funcionalidades));
                 break;
           case "3": // Chofer
                funcionalidades = ["menu_viajes_chofer"];
                sessionStorage.setItem('funcionalidades', JSON.stringify(funcionalidades));
                 break;  
           case "4": // Usuario corporativo
               funcionalidades = ["menu_solicitudes", "menu_solicitudes_corporativo"];
                 sessionStorage.setItem('funcionalidades', JSON.stringify(funcionalidades));
                 break;  
           case "5": // Usuario final / personalizado
                funcionalidades = ["menu_solicitudes"];
                sessionStorage.setItem('funcionalidades', JSON.stringify(funcionalidades));
                 break;               
           default: // Por defecto todos
                funcionalidades = ["menu_solicitudes"];
                sessionStorage.setItem('funcionalidades', JSON.stringify(funcionalidades));
               
         } 
   };


    const onFinish = async (values) => {
        // console.log('Valores', values);
        setLoading(true);
        if (values.user.trim() !== '' && values.pass.trim() !== '') {
            // console.log('ENTRE...');

            Axios.post('ht/login',
                {
                    "correo": values.user.trim(),
                    "contrasenna": values.pass.trim()
                },
                {
                    headers: headers
                }
            ).then(response =>{
                console.log('response', response);
                setLoading(false);
                if(response.data.success){
                    let info_user = response.data.data[0];
                    sessionStorage.setItem('idusuario', response.data.data[0].idusuario);
                    sessionStorage.setItem('info_user', JSON.stringify(info_user));
                    permisosPorRol(info_user);
                    window.location='/principal'
                    messageApi.open({
                        type: 'success',
                        content: response.data.message,
                        duration: 0,
                    });

                }else{
                    messageApi.open({
                        type: 'error',
                        content: response.data.message,
                        duration: 0,
                    });
                }

                setTimeout(messageApi.destroy, 2500);


            }).catch(error => {
                setLoading(false);
                sessionStorage.removeItem('idusuario');
                sessionStorage.removeItem('info_user');
                messageApi.open({
                    type: 'error',
                    content: 'Error de conexion con el servidor',
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

            });

        } else {
            setLoading(false);
            messageApi.open({
                type: 'error',
                content: 'Debe escribir correo y contraseña',
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);
        }

    };
    const changeIdioma = (value) => {
        sessionStorage.setItem('idioma', value);
        window.location='/';
      };


    return (
        <div>
            <div style={{
                           position:'absolute',
                           top:'10px',
                           right: '10px'
                        }}>

            <Image
               width={30}
               src={(idioma=="es")?flags_es:flags_us}
             />
            <Select
              value={idioma}
              style={{
                width: 120,
              }}
              onChange={changeIdioma}
              options={[
                {
                  value: 'es',
                  label: 'Español',
                },
                {
                  value: 'en',
                  label: 'Inglés',
                },
              ]}
            />

            </div>
            
           <Row justify="center" >
            {contextHolder}
            <Col
                flex="350px"
                style={{
                    marginTop: '5%',
                    // marginLeft: '5%',
                    backgroundColor: 'rgba(247, 247, 247, 0.33)',
                    // backgroundColor: '#f9f9f9',
                    border: '1px solid #369CB8',
                    borderRadius: 50,
                    padding: '20px 20px 0px 20px',
                    boxShadow: '0 1px 6px #369CB8'
                }}>

                <Card
                    // hoverable
                    style={{
                        borderRadius: 40,
                        padding: '20px 20px 0px 20px',
                        // border: '3px solid #369CB8',
                        // boxShadow: '0 1px 6px #2d2f3c'
                    }}
                    cover={<img src={Logo} />}
                >
                    {/*<Meta title="Europe Street beat" description="www.instagram.com" />*/}
                </Card>

                <Form
                    form={form}
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                        marginTop: '5%',
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    >

                    <Form.Item label={<FormattedMessage id="correo" defaultMessage="Correo" />} name='user' hasFeedback
                               rules={[
                                   {required: true, message: 'Por favor introduzca el correo'},
                                   { validator: validateEmail },
                               ]}
                    >
                        <Input placeholder='Introduzca el correo...'
                               className='inputLogin'
                               prefix={<UserOutlined />}
                               ref={autoFocusInput}  />
                    </Form.Item>

                    <Form.Item label={<FormattedMessage id="clave" defaultMessage="Contraseña" />} name='pass' hasFeedback
                               rules={[
                                   {required: true, message: 'Por favor introduzca la contraseña'},
                                   {min: 8, message: 'Debe introducir al menos 8 caracteres'}
                               ]}>
                        <Input.Password
                            placeholder='Introduzca la contraseña...'
                            className='inputLogin'
                            prefix={<LockOutlined />}
                        />
                    </Form.Item>

                    <Form.Item shouldUpdate
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                    <Button type="primary" htmlType="submit" icon={<LoginOutlined/>}
                            loading={loading}
                            // disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}>}
                    >
                        {<FormattedMessage id="btn_acceder" defaultMessage="Acceder" />}
                    </Button>
                    </Form.Item>
                </Form>
            </Col>
           </Row>
        </div>

        

    );
};
export default FormLoginHT;
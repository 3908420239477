import {Checkbox, Form, Input, Space, Button, message, Spin, InputNumber, TimePicker, DatePicker} from "antd";
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import moment from 'moment';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormModSolicViajeCharter = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    const [messageApi, contextHolder] = message.useMessage();

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando datos...");

    const [title] = useState((accion === "mod")?"Modificar Viaje Charter":"Adicionar Viaje Charter");

    const [horaSalida, setHoraSalida] = useState([]);

    const [idusuarioSession, setIdusuarioSession] = useState([]);

    const [correoSession, setCorreoSession] = useState([]);

    const [telefonoSession, setTelefonoSession] = useState([]);

    const [horaLlegada, setHoraLlegada] = useState([]);

    const [flagHoraSalida, setFlagHoraSalida] = useState(false);

    const [idsolicitudviaje, setidsolicitudviaje] = useState([]);

    const [idCategoriaServicio, setIdCategoriaServicio] = useState([]);

    const [idViajeCharterFecha, setIdViajeCharterFecha] = useState([]);

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    dayjs.extend(customParseFormat);
    const onTimePickerSalidaChange = (time, timeString) => {
        setHoraSalida(timeString);
        setFlagHoraSalida(true);
    };

    useEffect(() => {

        let info_user = JSON.parse(sessionStorage.getItem('info_user'));
        setCorreoSession(info_user.correo);
        setTelefonoSession(info_user.telefono);
        setIdusuarioSession(info_user.idusuario);

        if(accion === "mod"){
            if(!sessionStorage.getItem('charter_mod')){
                navigate('/solicviajecharter');
            }else{
                let charter_mod = JSON.parse(sessionStorage.getItem('charter_mod'));

                // console.log('charter_mod.idviajecharterfecha', charter_mod);

                setidsolicitudviaje(charter_mod.idsolicitudviaje);
                setIdCategoriaServicio(charter_mod.idcategoriaservicio);
                setHoraSalida(charter_mod.horasalida);
                setIdViajeCharterFecha(charter_mod.idviajecharterfecha);

                // let day = (charter_mod.fechaviaje.$D<10)?"0"+charter_mod.fechaviaje.$D:charter_mod.fechaviaje.$D;
                // let $M = charter_mod.fechaviaje.$M+1;
                // let month = ($M<10)?"0"+$M:$M;
                // let fecha = day+"/"+month+"/"+charter_mod.fechaviaje.$y;

                if(id !== charter_mod.idsolicitudviaje)
                    navigate('/editsolicviajecharter');

                form.setFieldsValue({
                    "idsolicitudviaje": idsolicitudviaje,
                    "idcategoriaservicio": idCategoriaServicio, // Charter
                    "idviajecharterfecha": idViajeCharterFecha,
                    "origen": charter_mod.origen,
                    "destino": charter_mod.destino,
                    "fechaviaje": dayjs(charter_mod.fechaviaje, 'DD/MM/YYYY'),
                    "horasalida": dayjs(charter_mod.horasalida, 'HH:mm'),
                    "correoregistro": correoSession,
                    "telefonousuariosolic": telefonoSession,
                    "cantninnos": charter_mod.cantninnos,
                    "cantadultos": charter_mod.cantadultos,
                    "cantsillaruedas": charter_mod.cantsillaruedas,
                    "idusuariocrea": idusuarioSession
                });
            }
        }

    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
    };


    useEffect(() => {
    }, []);

    const cancelar = (values) => {
        navigate('/solicviajecharter');
    };

    const onFinish = (values) => {
        setSpinning(true);

        // console.log('idsolicitudviaje', idsolicitudviaje);


        let day = (values.fechaviaje.$D<10)?"0"+values.fechaviaje.$D:values.fechaviaje.$D;
        let $M = values.fechaviaje.$M+1;
        let month = ($M<10)?"0"+$M:$M;
        let fecha = day+"/"+month+"/"+values.fechaviaje.$y;

        if(accion === "mod"){
            setSpinTitle('Modificando datos...');
            Axios.post("ht/updateSolicitudViaje",
                {
                    "idsolicitudviaje": idsolicitudviaje,
                    "idcategoriaservicio": idCategoriaServicio, // Charter
                    "idviajecharterfecha": idViajeCharterFecha,
                    "origen": values.origen,
                    "destino": values.destino,
                    "fechaviaje": fecha,
                    "horasalida": horaSalida,
                    "correoregistro": correoSession,
                    "telefonousuariosolic": telefonoSession,
                    "cantninnos": values.cantninnos,
                    "cantadultos": values.cantadultos,
                    "cantsillaruedas": values.cantsillaruedas,
                    "idusuariocrea": idusuarioSession
                },
                {
                    headers: headers
                }
            ).then(response => {
                setSpinning(false);
                if(response.data.success){
                    messageApi.open({
                        type: 'success',
                        content: response.data.message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);

                    navigate('/solicviajecharter');
                }else{
                    messageApi.open({
                        type: 'error',
                        content: response.data.message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);
                }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar los datos',
                        duration: 0,
                    });
                    setTimeout(messageApi.destroy, 2500);
                });
        }

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };
    const onChange = (date, dateString) => {
        console.log(dateString);
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div display="flex" align="middle">
                <h2 style={{textAlign:"center"}}>{title}</h2>
                {/*<p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>{title}</p>*/}
                {contextHolder}
                <Form
                    form={form}
                    autoComplete="on"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 10,
                    }}
                    name="form_in_modal"
                    initialValues={{
                        activo: 1,
                    }}
                >


                    <Form.Item
                        name="origen"
                        label="Origen"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor debe poner el Origen!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="destino"
                        label="Destino"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor debe poner el Destino!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="fechaviaje"
                        label="Fecha de viaje"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor seleccionar la fecha de viaje!',
                            }
                        ]}
                    >

                        <DatePicker
                            style={{width:'100%'}}
                            disabledDate={disabledDate}
                            onChange={onChange}
                            format='DD/MM/YYYY'
                        />

                    </Form.Item>

                    <Form.Item
                        name="horasalida"
                        label="Hora Salida"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor debe poner la Hora Salida!',
                            },
                        ]}
                    >
                        <TimePicker
                            onChange={onTimePickerSalidaChange}
                            placeholder="Seleccione Hora Salida"
                            //open={horaSalida}
                            // onOpenChange={onTimePickerSalidaChange}
                            format={'HH:mm'}
                            defaultOpenValue={dayjs('00:00', 'HH:mm')}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="cantninnos"
                        label="Cantidad de Niños"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor debe poner la Cantidad de Niños!',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="cantadultos"
                        label="Cantidad de Adultos"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor debe poner la Cantidad de Adultos!',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="cantsillaruedas"
                        label="Cantidad de Sillas de Rueda"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor debe poner la Cantidad de Sillas de Rueda!',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>

                    {/*<Form.Item*/}
                    {/*    name="regresa"*/}
                    {/*    label="Regresa"*/}
                    {/*    valuePropName="checked"*/}
                    {/*    noStyle*/}
                    {/*>*/}
                    {/*    <Checkbox >Regresa</Checkbox>*/}
                    {/*</Form.Item>*/}

                    <Form.Item {...tailLayout}>
                        <Space>
                            <Button danger onClick={cancelar}>
                                Cancelar
                            </Button>
                            <Button type="primary"  htmlType="submit">
                                Guardar
                            </Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>
        </Spin>

    );
};

export default FormModSolicViajeCharter;
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Spin, Space, Row, Col} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {} from '@ant-design/icons';
import {IoReload, IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import Search from "antd/es/input/Search";
import _ from "lodash";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

// const { Option } = Select;

const ViajePersonalizado = () => {

    if(sessionStorage.getItem('user_mod'))
       sessionStorage.removeItem('user_mod');

    const [bottom, setBottom] = useState('bottomCenter');

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);

    const [idusuarioSession, setIdusuarioSession] = useState([]);

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Cargando datos...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading] = useState(false);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: 'Categoría Servicio',
            dataIndex: 'categoriaservicio',
        },{
            title: 'Origen',
            dataIndex: 'origen',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.origen), _.isEqual),
            onFilter: (value, record) => record.origen.startsWith(value)
        },{
            title: 'Destino',
            dataIndex: 'destino',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.destino), _.isEqual),
            onFilter: (value, record) => record.destino.startsWith(value)
        },{
            title: 'Fecha Viaje',
            dataIndex: 'fechaviaje',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fechaviaje), _.isEqual),
            onFilter: (value, record) => record.fechaviaje.startsWith(value)
        },{
            title: 'Hora Salida',
            dataIndex: 'horasalida',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.horasalida), _.isEqual),
            onFilter: (value, record) => record.horasalida.startsWith(value)
        },{
            title: 'Tipo Transporte',
            dataIndex: 'tipotransporte',
            filterSearch: true,
            width: '15%',
            filters: _.uniqWith(filterData(data)(i => i.tipotransporte), _.isEqual),
            onFilter: (value, record) => record.tipotransporte.startsWith(value),
        },{
            title: 'Niños',
            dataIndex: 'cantninnos',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cantninnos), _.isEqual),
            onFilter: (value, record) => record.cantninnos.startsWith(value)
        },{
            title: 'Adultos',
            dataIndex: 'cantadultos',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cantadultos), _.isEqual),
            onFilter: (value, record) => record.cantadultos.startsWith(value)
        },{
            title: 'Silla de Ruedas',
            dataIndex: 'cantsillaruedas',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cantsillaruedas), _.isEqual),
            onFilter: (value, record) => record.cantsillaruedas.startsWith(value)
        },{
            title: 'Solicita',
            dataIndex: 'correoregistro',
        },{
            title: 'Teléfono',
            dataIndex: 'telefonousuariosolic',
        }/*,{
        title: 'Fecha Solicitud',
        dataIndex: 'fecharegistro',
    }*/
    ];

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        let info_user = JSON.parse(sessionStorage.getItem('info_user'));
        recargar(info_user);
    }, []);

    const recargar = (info_user) => {
        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;

        if(!info_user.idusuario){
            info_user.idusuario = JSON.parse(sessionStorage.getItem('info_user')).idusuario;
        }

        Axios.post("ht/getSolicitudViaje",
            {
                "idcategoriaservicio": 1,
                "idusuariocrea": info_user.idusuario
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            // console.log('usuarios', response.data);
            if(response.data.data.length>0){
                for(var i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idsolicitudviaje,
                        idsolicitudviaje:response.data.data[i].idsolicitudviaje,
                        idcategoriaservicio:response.data.data[i].idcategoriaservicio,
                        categoriaservicio:response.data.data[i].categoriaservicio,
                        idviajecharterfecha:response.data.data[i].idviajecharterfecha,
                        origen:response.data.data[i].origen,
                        destino:response.data.data[i].destino,
                        fechaviaje:response.data.data[i].fechaviaje,
                        horasalida:response.data.data[i].horasalida,
                        fecharegistro:response.data.data[i].fecharegistro,
                        correoregistro:response.data.data[i].correoregistro,
                        telefonousuariosolic:response.data.data[i].telefonousuariosolic,
                        tipotransporte:response.data.data[i].tipotransporte,
                        idtipotransporte:response.data.data[i].idtipotransporte,
                        cantninnos:response.data.data[i].cantninnos,
                        cantadultos:response.data.data[i].cantadultos,
                        cantsillaruedas:response.data.data[i].cantsillaruedas,
                        caracteristicaomnibus:response.data.data[i].caracteristicaomnibus.replace(/"/g, '')
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        }).catch(error => {
                setSpinning(false);
            });
    };

    const getInfoForm = async (id) => {
        for(let i=0;i<data.length;i++){
            if(data[i].idsolicitudviaje === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('user_mod', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar datos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea eliminar los datos seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando datos...");
                setSpinning(true);
                Axios.delete('ht/deleteSolicitudViaje',
                    {
                      data: {
                        "idsolicitudviaje": selectedRowKeys
                      },
                      headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al eliminar datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });
            },
        });
    };


    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.fechaviaje.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idsolicitudviaje === record.key){
                row = data[i];
            }
        }

        // console.log('ROW : ', row);
       
        sessionStorage.setItem('user_mod', JSON.stringify(row));
        navigate(`/editviajepersonalizado/${record.key}`)
        
    };


    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
        <div>
            {/*<h2 style={{textAlign:"center"}}>Configurar Ruta Charter</h2>*/}
            <p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>Solicitar Viaje Personalizado</p>
            {contextHolder}
            <div
                style={{
                    marginBottom: 10,
                }}
            >
                {contextHolder}
                <Space direction='horizontal'>
                    <Link to="/addviajepersonalizado">
                        <Button type="primary" className="button" icon={<MdAddHome />} disabled={loading}>
                            Solicitar
                        </Button>
                    </Link>
                    <Link to={`/editviajepersonalizado/${selectedRowKeys[0]}`}>
                        <Button type="primary" className="button" icon={<AiFillEdit />} onClick={modificar} disabled={loading || selectedRowKeys.length !== 1}>
                            Modificar
                        </Button>
                    </Link>
                        <Button type="primary" danger className="button" icon={<AiFillDelete />} onClick={eliminar}
                                disabled={!hasSelected || loading}>
                            Eliminar
                        </Button>
                    <Button type="primary" className="button" icon={<IoReload />} onClick={recargar} loading={loading || loading}>
                        Recargar
                    </Button>

                    <Search
                        placeholder="Fecha Viaje"
                        enterButton
                        style={{
                            width: 250,
                        }}
                        size="middle"
                        onSearch={onSearch}
                        onChange={(e) => {
                            filterLocal(e.target.value);
                        }}
                    />
                </Space>
            </div>
 

            <Row>
                <Col span={24}>
                    <Table size="small"
                           // scroll={{
                           //     y: 400,
                           // }}
                           //bordered={true}
                           pagination={{
                               position: [bottom],
                               pagination: {
                                   current: 1,
                                   pageSize: 10,
                               }}}
                           rowSelection={rowSelection}
                           columns={columns} dataSource={data}
                           onRow={(record) => {
                               return {
                                   onDoubleClick: async () => {
                                       selectRowUpdate(record);
                                   }
                               }
                           }}/>
                </Col>
            </Row>


         </div>
        </Spin>
    );
};
export default ViajePersonalizado;
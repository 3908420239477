import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import {Checkbox,Row, InputNumber, TimePicker, Form, Space, 
    Button, message, Spin, Col, Input, DatePicker, Select } from "antd";

 
import {IoReload, IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import dayjs from 'dayjs';
import { bool } from "prop-types";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});


const FormModViajeCharter = ({}) => {

    const navigate = useNavigate();

    const { id } = useParams();

    const { Option } = Select;

    const [form] = Form.useForm();

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [messageApi, contextHolder] = message.useMessage();

    const [horaSalida, setHoraSalida] = useState(null);

    const [horaLlegada, setHoraLlegada] = useState(null);

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando información ...");

    const [datosOriginales, setDatosOriginales] = useState(null);

    const [loading, setLoading] = useState(false);

    const [vehiculosOriginal, setVehiculosOriginal] = useState([]);

    const [vehiculos, setVehiculos] = useState([]);
    
    const loadVehiculos = (viaje_charter_mod) => {
        setLoading(true);
        let aux = [];

        let chequeados = (viaje_charter_mod.idomnibus === null)?[]:
                       viaje_charter_mod.idomnibus.replaceAll("{","").replaceAll("}","").split(',');   

        console.log('chequeados', chequeados);

        form.setFieldsValue({
            "idomnibus": []});

        Axios.post("ht/getVehiculo",
                {
                    "filtros": [
                      {
                        "idtipotransporte": viaje_charter_mod.idtipotransporte,
                        "idviajecharterfecha":""
                      }
                    ]
                  },
            {
                headers: headers
            }
        ).then(response => {
                setLoading(false);
                
                if(response.data.data.length > 0){
                    for(var i=0;i<response.data.data.length;i++){
                            aux.push({
                                id: response.data.data[i].idomnibus,
                                denominacion: response.data.data[i].denominacion,
                                chequeado: response.data.data[i].chequeado,
                                enuso: response.data.data[i].enuso,
                            });
                    }
                }
                setVehiculos(aux);
                setVehiculosOriginal(aux);
                form.setFieldsValue({
                    "idomnibus": chequeados});

            })
            .catch(error => {
                setLoading(false);
                setVehiculos(aux);
                setVehiculosOriginal(aux);
                form.setFieldsValue({
                    "idomnibus": []});
                
            })
    };

    useEffect(() => {
            if(!sessionStorage.getItem('viaje_charter_mod')){
                navigate('/viajecharter');
            }else{
                let viaje_charter_mod = JSON.parse(sessionStorage.getItem('viaje_charter_mod'));

                setDatosOriginales(viaje_charter_mod);
    
                if(id !== viaje_charter_mod.idviajecharterfecha)
                   navigate('/viajecharter')

                   setHoraLlegada(viaje_charter_mod.horallegada);
                   setHoraSalida(viaje_charter_mod.horasalida);


                   form.setFieldsValue({
                       nombreruta:viaje_charter_mod.nombreruta,
                       fechaviaje:dayjs(viaje_charter_mod.fechaviaje, 'DD/MM/YYYY'),
                       precioruta:viaje_charter_mod.precioruta,
                       preciorutaninno:viaje_charter_mod.preciorutaninno,
                       origen:viaje_charter_mod.origen,
                       destino:viaje_charter_mod.destino,
                       horallegada: dayjs(viaje_charter_mod.horallegada, 'HH:mm'),
                       horasalida: dayjs(viaje_charter_mod.horasalida, 'HH:mm'), 
                       activa:(viaje_charter_mod.activa=="1"),
                       sobreventa:(viaje_charter_mod.sobreventa=="1"),
                       sillaruedas:(viaje_charter_mod.sillaruedas=="1"),
                       regresa:(viaje_charter_mod.regresa=="1")
                      
                    });    

                    loadVehiculos(viaje_charter_mod);
    
            }
    }, []);

 
    const tailLayout = {
        wrapperCol: { marginTop:5, offset: 8, span: 16 },
      };


    const cancelar = (values) => {
        navigate('/viajecharter');
    };
    
    const onChange = (date, dateString) => {
        console.log(dateString);
    };

    const onTimePickerSalidaChange = (time, timeString) => {
        setHoraSalida(timeString);
         
    };
    const onTimePickerLlegadaChange = (time, timeString) => {
        setHoraLlegada(timeString);
    };


    const onFinish = (values) => {

        // console.log("Datos del Form", values);

        setSpinTitle('Guardando datos...');

        let day = (values.fechaviaje.$D<10)?"0"+values.fechaviaje.$D:values.fechaviaje.$D;
        let $M = values.fechaviaje.$M+1;
        let month = ($M<10)?"0"+$M:$M;
        let fecha = day+"/"+month+"/"+values.fechaviaje.$y;


        console.log(fecha);

        Axios.post("ht/updateViajeCharterFecha",
                    {
                       "idviajecharterfecha": id,
                       "nombreruta": values.nombreruta,
                       "origen": values.origen,
                       "destino": values.destino,
                       "horasalida": horaSalida,
                       "horallegada": horaLlegada,
                       "precioruta": values.precioruta,
                       "preciorutaninno": values.preciorutaninno,
                       "regresa": (values.regresa)?1:0,
                       "activa": (values.activa)?1:0,
                       "sillaruedas": (values.sillaruedas)?1:0,
                       "sobreventa": (values.sobreventa)?1:0,
                       "idtipotransporte": datosOriginales.idtipotransporte,
                       "fechaviaje": fecha,
                       "idomnibus": values.idomnibus,
                     },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/viajecharter');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar los datos',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        
      };
      const onFinishFailed = (errorInfo) => {
       
      };

      const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
      };

      const changeVehiculos = (value) => {
        let enuso = value;
        for(var i=0;i<vehiculosOriginal.length;i++){
            if(vehiculosOriginal[i].enuso == 1 && value.indexOf(vehiculosOriginal[i].id)==-1){
                enuso.push(vehiculosOriginal[i].id);
            }
         }
         form.setFieldsValue({
            "idomnibus": enuso});
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            <p style={{textAlign:"center", margin:15, fontSize:'20px', fontWeight:'bold'}}>Modificar Viaje Charter</p>
            {contextHolder}
                 <Form
                     form={form}
                     name="three_column_form"
                     labelCol={{ span: 12 }}
                     wrapperCol={{ span: 16 }}
                     onFinish={onFinish}
                 >
                     <Row gutter={24}>
                         <Col span={11}>
                             {/*columna 1*/}
                             <Form.Item
                                 name="fechaviaje"
                                 label="Fecha de viaje"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor seleccionar la fecha de viaje!',
                                     }
                                 ]}
                             >

                                 <DatePicker
                                     //style={{width:200}}
                                     style={{
                                         width: '100%',
                                     }}
                                     disabledDate={disabledDate}
                                     onChange={onChange}
                                     format='DD/MM/YYYY'
                                 />

                             </Form.Item>

                             <Form.Item
                                 name="origen"
                                 label="Origen"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el origen!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="horasalida"
                                 label="Hora de salida"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner la hora de salida!',
                                     },
                                 ]}
                             >
                                 <TimePicker
                                     placeholder="Seleccione Hora Salida"
                                     format={'HH:mm'}
                                     defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                     onChange={onTimePickerSalidaChange}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="precioruta"
                                 label="Precio Adulto"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el Precio Adulto!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     addonBefore="$"
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="idomnibus"
                                 label="Vehículos"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Debe seleccionar al menos una opción!',
                                         type: 'array',
                                     },
                                 ]}
                             >
                                 <Select
                                     onChange={changeVehiculos}
                                     mode="multiple"
                                     placeholder="Seleccionar vehículos"
                                     loading={loading}>
                                     {vehiculos.map((features) => (
                                         <Option value={features.id}>
                                             {features.denominacion}
                                         </Option>
                                     ))}
                                 </Select>
                             </Form.Item>

                         </Col>
                         <Col span={11} >
                             {/*columna 2*/}
                             <Form.Item
                                 name="nombreruta"
                                 label="Nombre de la Ruta"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el nombre de la ruta!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="destino"
                                 label="Destino"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el destino!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="horallegada"
                                 label="Hora de llegada"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner la hora de llegada!',
                                     },
                                 ]}
                             >
                                 <TimePicker
                                     placeholder="Seleccione Hora Salida"
                                     format={'HH:mm'}
                                     defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                     onChange={onTimePickerLlegadaChange}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="preciorutaninno"
                                 label="Precio Niño"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe poner el Precio Niño!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     addonBefore="$"
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>


                         </Col>
                         {/*<Col span={8} >*/}
                         {/*    /!*columna 3*!/*/}



                         {/*</Col>*/}
                     </Row>

                     <Row gutter={24}>
                         <Col span={24}>
                             <Form.Item
                                 name="activa"
                                 label="Activa"
                                 valuePropName="checked"
                                 noStyle
                             >
                                 <Checkbox ><p style={{fontWeight:'bold'}}>Activa</p></Checkbox>
                             </Form.Item>

                             <Form.Item
                                 name="sillaruedas"
                                 label="sillaruedas"
                                 valuePropName="checked"
                                 noStyle
                             >
                                 <Checkbox ><p style={{fontWeight:'bold'}}>Silla de ruedas</p></Checkbox>
                             </Form.Item>

                             <Form.Item
                                 name="sobreventa"
                                 label="Sobreventa"
                                 valuePropName="checked"
                                 noStyle
                             >
                                 <Checkbox ><p style={{fontWeight:'bold'}}>Sobreventa</p></Checkbox>
                             </Form.Item>

                             <Form.Item
                                 name="regresa"
                                 label="regresa"
                                 valuePropName="checked"
                                 noStyle
                             >
                                 <Checkbox ><p style={{fontWeight:'bold'}}>Regresa</p></Checkbox>
                             </Form.Item>
                         </Col>


                     </Row>

                     <Form.Item wrapperCol={{ offset: 12, span: 16 }} {...tailLayout}>
                         <Space>

                             <Button danger onClick={cancelar}>
                                 Cancelar
                             </Button>

                             <Button type="primary"  htmlType="submit">
                                 Guardar
                             </Button>


                         </Space>
                     </Form.Item>
                 </Form>


        </div>
        </Spin>
       
    );
};

export default FormModViajeCharter;
import {
    Checkbox,
    Form,
    Input,
    Space,
    Button,
    message,
    Spin,
    InputNumber,
    TimePicker,
    DatePicker,
    Row,
    Col,
    Select, Card
} from "antd";
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import GlobalSelect from "../../generales/GlobalSelect";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import moment from 'moment';

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});



const FormViajePersonalizado = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [messageApi, contextHolder] = message.useMessage();

    const { TextArea } = Input;

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando datos...");

    const [title] = useState((accion === "mod")?"Modificar Viaje Personalizado":"Solicitar Viaje Personalizado");

    const [horaSalida, setHoraSalida] = useState([]);

    const [idusuarioSession, setIdusuarioSession] = useState([]);

    const [correoSession, setCorreoSession] = useState([]);

    const [telefonoSession, setTelefonoSession] = useState([]);

    const [horaLlegada, setHoraLlegada] = useState([]);

    const [flagHoraSalida, setFlagHoraSalida] = useState(false);

    const [idsolicitudviaje, setidsolicitudviaje] = useState([]);

    const [idCategoriaServicio, setIdCategoriaServicio] = useState([]);

    const [caracteristicaOmnibus, setCaracteristicaOmnibus] = useState('fer');

    const [loading, setLoading] = useState(false);

    const [tipotransporte, setTipotransporte] = useState([]);

    const navigate = useNavigate();

    const { id } = useParams();

    const [form] = Form.useForm();

    dayjs.extend(customParseFormat);
    const onTimePickerSalidaChange = (time, timeString) => {
        setHoraSalida(timeString);
        setFlagHoraSalida(true);
    };

    useEffect(() => {
        cargarTipoTransporte();

        let info_user = JSON.parse(sessionStorage.getItem('info_user'));
        setCorreoSession(info_user.correo);
        setTelefonoSession(info_user.telefono);
        setIdusuarioSession(info_user.idusuario);

        if(accion === "mod"){
            if(!sessionStorage.getItem('user_mod')){
                navigate('/viajepersonalizado');
            }else{
                let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));

                // console.log('user_mod.idsolicitudviaje', user_mod);

                setidsolicitudviaje(user_mod.idsolicitudviaje);
                setIdCategoriaServicio(user_mod.idcategoriaservicio);
                setHoraSalida(user_mod.horasalida);

                // let day = (user_mod.fechaviaje.$D<10)?"0"+user_mod.fechaviaje.$D:user_mod.fechaviaje.$D;
                // let $M = user_mod.fechaviaje.$M+1;
                // let month = ($M<10)?"0"+$M:$M;
                // let fecha = day+"/"+month+"/"+user_mod.fechaviaje.$y;

                if(id !== user_mod.idsolicitudviaje)
                   navigate('/editviajepersonalizado');

                form.setFieldsValue({
                    "idsolicitudviaje": idsolicitudviaje,
                    "idcategoriaservicio": idCategoriaServicio, // Personalizado
                    "idviajecharterfecha": null,
                    "origen": user_mod.origen,
                    "destino": user_mod.destino,
                    "fechaviaje": dayjs(user_mod.fechaviaje, 'DD/MM/YYYY'),
                    // "fechaviaje": fecha,
                    "horasalida": dayjs(user_mod.horasalida, 'HH:mm'),
                    "correoregistro": correoSession,
                    "telefonousuariosolic": telefonoSession,
                    "tipotransporte": user_mod.idtipotransporte,
                    "cantninnos": user_mod.cantninnos,
                    "cantadultos": user_mod.cantadultos,
                    "cantsillaruedas": user_mod.cantsillaruedas,
                    "idusuariocrea": idusuarioSession,
                    "caracteristicaomnibus": user_mod.caracteristicaomnibus.replace(/,/g, ' / ')
                    //(tipotransporte.caracteristicaomnibus.replace(/"/g, '')).replace(/"/g, '').replace(/,/g, ' / ') });
                    });
            }
        }

    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };


    useEffect(() => {
    }, []);

    const cancelar = (values) => {
        navigate('/viajepersonalizado');
    };

    const onSelectTipoTransporte = (value, tipotransporte) => {
        form.setFieldsValue({ caracteristicaomnibus: (tipotransporte.caracteristicaomnibus.replace(/"/g, '')).replace(/"/g, '').replace(/,/g, ' / ') });
    };


    const cargarTipoTransporte = () => {
        setLoading(true);
        let aux = [];
        let start = 0;
        let limit = 1000;
        Axios.get(`ht/getTipoTransporte?start=${start}&limit=${limit}`,{
            headers: headers
        })
            .then(response => {
                setLoading(false);
                if(response.data.data.length>0){
                    for(var i=0;i<response.data.data.length;i++){
                        aux.push({
                            id:response.data.data[i].idtipotransporte,
                            nombre:response.data.data[i].denominacion,
                            caracteristicaomnibus:response.data.data[i].caracteristicaomnibus,
                        });
                    }
                }
                setTipotransporte(aux);
            })
            .catch(error => {
                setLoading(false);
                setTipotransporte(aux);
                //console.log("Error",error);
            })
    };

    const onFinish = (values) => {
        setSpinning(true);

        console.log('solicitarViaje', values);


        let day = (values.fechaviaje.$D<10)?"0"+values.fechaviaje.$D:values.fechaviaje.$D;
        let $M = values.fechaviaje.$M+1;
        let month = ($M<10)?"0"+$M:$M;
        let fecha = day+"/"+month+"/"+values.fechaviaje.$y;

        if(accion === "add"){
            setSpinTitle('Guardando datos...');
            Axios.post("ht/solicitarViaje",
                    {
                      "idcategoriaservicio": 1, // Personalizado
                      "idviajecharterfecha": null,
                      "origen": values.origen,
                      "destino": values.destino,
                      "fechaviaje": fecha,
                      "horasalida": horaSalida,
                      "correoregistro": correoSession,
                      "telefonousuariosolic": telefonoSession,
                      "idtipotransporte": values.tipotransporte,
                      "cantninnos": values.cantninnos,
                      "cantadultos": values.cantadultos,
                      "cantsillaruedas": values.cantsillaruedas,
                      "idusuariocrea": idusuarioSession
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/viajepersonalizado');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar los datos',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }else if(accion === "mod"){
            setSpinTitle('Modificando datos...');
            Axios.post("ht/updateSolicitudViaje",
            {
                "idsolicitudviaje": idsolicitudviaje,
                "idcategoriaservicio": idCategoriaServicio, // Personalizado
                "idviajecharterfecha": null,
                "origen": values.origen,
                "destino": values.destino,
                "fechaviaje": fecha,
                "horasalida": horaSalida,
                "correoregistro": correoSession,
                "telefonousuariosolic": telefonoSession,
                "idtipotransporte": values.tipotransporte,
                "cantninnos": values.cantninnos,
                "cantadultos": values.cantadultos,
                "cantsillaruedas": values.cantsillaruedas,
                "idusuariocrea": idusuarioSession
            },
            {
                headers: headers
              }
        ).then(response => {
          setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

                navigate('/viajepersonalizado');
            }else{
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al guardar los datos',
                duration: 0,
            });
            setTimeout(messageApi.destroy, 2500);
        });
        }

      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };
    const onChange = (date, dateString) => {
        console.log(dateString);
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            {/*<h2 style={{textAlign:"center"}}>{title}</h2>*/}
            {/*<p style={{textAlign:"center", margin:0, fontSize:'20px', fontWeight:'bold'}}>{title}</p>*/}
            {contextHolder}
                 <Card title={<h2 style={{textAlign:"center"}}>{title}</h2>} style={{ width: '70%' }}>
                 <Form
                     form={form}
                     name="three_column_form"
                     labelCol={{ span: 12 }}
                     wrapperCol={{ span: 16 }}
                     onFinish={onFinish}
                     initialValues={{ cantadultos: 1, cantninnos: 0, cantsillaruedas: 0 }}
                 >
                     <Row gutter={24}>
                         <Col span={11}>
                             {/*columna 1*/}
                             <Form.Item
                                 name="origen"
                                 label="Origen"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar el Origen!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="fechaviaje"
                                 label="Fecha de viaje"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor seleccionar la fecha de viaje!',
                                     }
                                 ]}
                             >

                                 <DatePicker
                                     style={{width:'100%'}}
                                     disabledDate={disabledDate}
                                     onChange={onChange}
                                     format='DD/MM/YYYY'
                                 />

                             </Form.Item>

                             <Form.Item
                                 name="tipotransporte"
                                 label="Tipo de Transporte"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor seleccione el tipo de transporte del servicio!',
                                     },
                                 ]}>

                                 <GlobalSelect onSelect={onSelectTipoTransporte} loading={loading} valueName={'tipotransporte'} data={tipotransporte}/>

                             </Form.Item>

                             <Form.Item
                                 name="caracteristicaomnibus"
                                 label="Características"
                             >
                                 <TextArea
                                     readOnly
                                     rows={4}
                                     style={{
                                         height: 100,
                                         resize: 'none',
                                     }}
                                 />
                             </Form.Item>

                         </Col>
                         <Col span={11} >
                             {/*columna 2*/}
                             <Form.Item
                                 name="destino"
                                 label="Destino"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar el Destino!',
                                     },
                                 ]}
                             >
                                 <Input />
                             </Form.Item>

                             <Form.Item
                                 name="horasalida"
                                 label="Hora Salida"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar la Hora Salida!',
                                     },
                                 ]}
                             >
                                 <TimePicker
                                     onChange={onTimePickerSalidaChange}
                                     placeholder="Seleccione Hora Salida"
                                     //open={horaSalida}
                                     // onOpenChange={onTimePickerSalidaChange}
                                     format={'HH:mm'}
                                     defaultOpenValue={dayjs('00:00', 'HH:mm')}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="cantadultos"
                                 label="Cantidad de Adultos"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar la Cantidad de Adultos!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     min={1}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>

                             <Form.Item
                                 name="cantninnos"
                                 label="Cantidad de Niños"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar la Cantidad de Niños!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     min={0}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>



                             <Form.Item
                                 name="cantsillaruedas"
                                 label="Cantidad de Sillas de Rueda"
                                 rules={[
                                     {
                                         required: true,
                                         message: 'Por favor debe insertar la Cantidad de Sillas de Rueda!',
                                     },
                                 ]}
                             >
                                 <InputNumber
                                     min={0}
                                     style={{
                                         width: '100%',
                                     }}
                                 />
                             </Form.Item>



                         </Col>
                         {/*<Col span={8} >*/}
                         {/*    /!*columna 3*!/*/}



                         {/*</Col>*/}
                     </Row>

                     {/*<Row gutter={24}>*/}
                     {/*    <Col span={24}>*/}


                     {/*    </Col>*/}


                     {/*</Row>*/}

                     <Form.Item wrapperCol={{ offset: 12, span: 16 }} {...tailLayout}>
                         <Space>

                             <Button danger onClick={cancelar}>
                                 Cancelar
                             </Button>
                             <Button type="primary"  htmlType="submit">
                                 Solicitar Cotización
                             </Button>

                         </Space>
                     </Form.Item>
                 </Form>
                 </Card>

        </div>
        </Spin>

    );
};

export default FormViajePersonalizado;
import { useRef, useEffect } from "react";

// hook para darle focus a un elemento, se crea y se le pasa a la propiedad ref de un elemento. 
const useAutoFocus = (
  conditions = [] // [array] array de condiciones a en las que se va a activar el focus del elemento. 
  // (defecto):Array vacio. Cuando se cree el elemento. 
) => {
  const autoFocusInput = useRef(null);
  useEffect(() => {
    setTimeout(() => {
        if (autoFocusInput.current) {
            autoFocusInput.current.focus();
        }
    }, 100);
  }, conditions);

  return autoFocusInput;
};

export default useAutoFocus;
import {Form, Input, Select, Space, Button, message, Spin} from "antd";
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import {arrayOf} from "prop-types";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormAddTipoTransporte = ({accion}) => {

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);

    const [features, setFeatures] = useState([]);

    // const [arrayFeatures, setArrayFeatures] = useState([]);

    // const [options, setOptions] = useState([]);

    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando información ...");

    const [title] = useState((accion === "mod")?"Modificar Tipo Transporte":"Adicionar Tipo Transporte");

    const navigate = useNavigate();

    const { id } = useParams();

    const { Option } = Select;

    const [form] = Form.useForm();

    useEffect(() => {
        if(accion === "mod"){
            if(!sessionStorage.getItem('user_mod')){
                navigate('/tipotransporte');
            }else{

                let user_mod = JSON.parse(sessionStorage.getItem('user_mod'));

                // if(id !== user_mod.idtipotransporte)
                //    navigate('/tipotransporte')

                // console.log('idcaracteristicaomnibus', user_mod.idcaracteristicaomnibus);

                form.setFieldsValue({
                    "idtipotransporte": user_mod.id,
                    "denominacion": user_mod.denominacion,
                    "marca": user_mod.marca,
                    "idcaracteristicaomnibus": JSON.parse(user_mod.idcaracteristicaomnibus)
                    });
            }
               
        }


    }, []);

    const loadFeatures = () => {
        setLoading(true);
        let aux = [];
        let start = 0;
        let limit = 1000;

        Axios.get(`ht/getCaractTransporte?start=${start}&limit=${limit}`, {
            headers: headers
        })
            .then(response => {
                setLoading(false);
                // console.log(response.data.data);
                if(response.data.data.length > 0){
                    for(var i=0;i<response.data.data.length;i++){
                        aux.push({
                            id: response.data.data[i].idcaracteristicaomnibus,
                            denominacion: response.data.data[i].denominacion,
                            // denominacion: response.data.data[i].denominacion.replace(/"/gi, "\""),
                        });
                    }
                }
                setFeatures(aux);
            })
            .catch(error => {
                setLoading(false);
                setFeatures(aux);
                console.log("Error",error);
            })
    };

    useEffect(() => {
        loadFeatures();
    }, []);

    const tailLayout = {
        wrapperCol: { marginTop:10, offset: 8, span: 16 },
      };


    const cancelar = (values) => {
        navigate('/tipotransporte');
    };

    const onFinish = (values) => {
        // console.log('Success:', values);

        form.setFieldsValue({
            "idtipotransporte": values.idtipotransporte,
            "denominacion": values.denominacion,
            "marca": values.marca,
            "idcaracteristicaomnibus": values.idcaracteristicaomnibus
            });
        setSpinning(true);
        if(accion === "add"){
            setSpinTitle('Guardando datos...');
            Axios.post("ht/addTipoTransporte",
                    {
                      "denominacion": values.denominacion,
                      "marca": values.marca,
                      "idcaracteristicaomnibus": values.idcaracteristicaomnibus
                    },
                    {
                        headers: headers
                      }
                ).then(response => {
                  setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        navigate('/tipotransporte');
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
            })
                .catch(error => {
                    setSpinning(false);
                    messageApi.open({
                        type: 'error',
                        content: 'Error al guardar los datos',
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);


                });
        }else if(accion === "mod"){
            setSpinTitle('Modificando datos...');
            Axios.post("ht/updateTipoTransporte",
            {
              "idtipotransporte": id,
              "denominacion": values.denominacion,
              "marca": values.marca,
              "idcaracteristicaomnibus": values.idcaracteristicaomnibus
            },
            {
                headers: headers
              }
        ).then(response => {
          setSpinning(false);
            if(response.data.success){
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);

                navigate('/tipotransporte');
            }else{
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            }
        })
        .catch(error => {
            setSpinning(false);
            messageApi.open({
                type: 'error',
                content: 'Error al guardar los datos',
                duration: 0,
            });

            setTimeout(messageApi.destroy, 2500);
        });
        }

      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div display="flex" align="middle">
            <h2 style={{textAlign:"center"}}>{title}</h2>
            {contextHolder}
             <Form
                form={form}
                autoComplete="on"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 10,
                  }}
                name="form_in_modal"
                initialValues={{
                    activo: 1,
                }}
            >
                <Form.Item
                    // hidden={(accion=="mod")}
                    name="denominacion"
                    label="Denominación"
                    // rules={[
                    //     {
                    //         required: (accion!="pass"),
                    //         message: 'Por favor debe poner el nombre!',
                    //     },
                    // ]}
                >
                    <Input />
                </Form.Item>

                 <Form.Item
                     // hidden={(accion=="mod")}
                     name="marca"
                     label="Marca"
                     // rules={[
                     //     {
                     //         required: (accion!="pass"),
                     //         message: 'Por favor debe poner el nombre!',
                     //     },
                     // ]}
                 >
                     <Input />
                 </Form.Item>

                 <Form.Item
                     name="idcaracteristicaomnibus"
                     label="Característica"
                     rules={[
                         {
                             // required: true,
                             message: 'Debe seleccionar al menos una opción!',
                             type: 'array',
                         },
                     ]}
                 >
                     <Select mode="multiple" placeholder="Seleccione al menos una opción!" loading={loading}>
                         {features.map((features) => (
                             <Option value={features.id}>
                                 {features.denominacion}
                             </Option>
                         ))}
                     </Select>
                 </Form.Item>

                <Form.Item {...tailLayout}>
                            <Space>

                                <Button danger onClick={cancelar}>
                                    Cancelar
                                  </Button>

                                <Button type="primary"  htmlType="submit">
                                    Guardar
                                </Button>


                            </Space>
                </Form.Item>
                
            </Form>

        </div>
        </Spin>
       
    );
};

export default FormAddTipoTransporte;
import React, {useEffect, useState}  from "react";
import {useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import {Checkbox, Row, Col, Form, Space, Button, message, Spin, Table, DatePicker, InputNumber, Input} from "antd";
import {IoReload, IoCheckmarkCircle, IoCloseCircle, IoSearch } from "react-icons/io5";
import dayjs from 'dayjs';
import { bool } from "prop-types";
import _ from "lodash";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});


const StepSolicViajeCharter = ({setDisabled}) => {

    const [form] = Form.useForm();

    const changeDisabled = (value) => {
        setDisabled(value)
      }

    const [data, setData] = useState([]);
    const [original, setOriginal] = useState([]);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: 'Fecha Viaje',
            dataIndex: 'fechaviaje',
            //width: '10%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.fechaviaje), _.isEqual),
            onFilter: (value, record) => record.fechaviaje.startsWith(value)
        },{
            title: 'Nombre Ruta',
            dataIndex: 'nombreruta',
            //width: '10%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.nombreruta), _.isEqual),
            onFilter: (value, record) => record.nombreruta.startsWith(value)
        },{
            title: 'Origen',
            dataIndex: 'origen',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.origen), _.isEqual),
            onFilter: (value, record) => record.origen.startsWith(value)
        },{
            title: 'Destino',
            dataIndex: 'destino',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.destino), _.isEqual),
            onFilter: (value, record) => record.destino.startsWith(value)
        },{
            title: 'Hora Salida',
            dataIndex: 'horasalida',
            //width: '10%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.horasalida), _.isEqual),
            onFilter: (value, record) => record.horasalida.startsWith(value)
        },{
            title: 'Hora Llegada',
            dataIndex: 'horallegada',
            //width: '10%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.horallegada), _.isEqual),
            onFilter: (value, record) => record.horallegada.startsWith(value)
        },{
            title: 'Precio Adulto',
            dataIndex: 'precioruta',
            //width: '10%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.precioruta), _.isEqual),
            onFilter: (value, record) => record.precioruta.startsWith(value)
        },{
            title: 'Precio Niño',
            dataIndex: 'preciorutaninno',
            //width: '10%',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.preciorutaninno), _.isEqual),
            onFilter: (value, record) => record.preciorutaninno.startsWith(value)
        },{
            title: 'Regresa',
            dataIndex: 'regresa',
            //width: '6%',
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Activo',
            dataIndex: 'activa',
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Sobreventa',
            dataIndex: 'sobreventa',
            //width: '8%',
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        },{
            title: 'Silla de ruedas',
            dataIndex: 'sillaruedas',
            //width: '10%',
            render: (text) => (text === "1")?<IoCheckmarkCircle size={20} color={'green'}/>:<IoCloseCircle size={20} color={'red'}/>,
        }
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };


    const [spinning, setSpinning] = useState(false);

    const [spinTitle, setSpinTitle] = useState("Guardando información ...");


    
    const onChange = (date, dateString) => {
        console.log(dateString);
    };

    const onSelectChange = async (newSelectedRowKeys)  => {
        setSelectedRowKeys(newSelectedRowKeys);
        let selected = await getRecordSelected(newSelectedRowKeys[0]);
        console.log('selected',selected);
        sessionStorage.setItem('ruta', JSON.stringify(selected));
        form.setFieldsValue({ rutas: selected});
        changeDisabled(false);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getRecordSelected = async (id) => {
        for(let i=0;i<original.length;i++){
            if(original[i].idviajecharterfecha === id){
                return original[i];
            }
        }
        return [];
    };


    const onFinish = (values) => {

        setSpinTitle("Cargando datos...");

        sessionStorage.setItem('cantadultos', values.cantadultos);
        sessionStorage.setItem('cantninnos', values.cantninnos);
        sessionStorage.setItem('cantsillaruedas', values.cantsillaruedas);

        let day = (values.fechaviaje.$D<10)?"0"+values.fechaviaje.$D:values.fechaviaje.$D;
        let $M = values.fechaviaje.$M+1;
        let month = ($M<10)?"0"+$M:$M;
        let fecha = day+"/"+month+"/"+values.fechaviaje.$y;

        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        let start = 1;
        let limit = 1000;
        let capacidad = values.cantadultos+values.cantninnos;
        Axios.post("ht/getViajeCharterFecha",
        {
            "start": start,
            "limit": limit,
            "filtros": [
                {
                    "origen": (values.origen!=null)?values.origen:"",
                    "destino": (values.destino!=null)?values.destino:"",
                    "fechaviaje": fecha,
                    "capacidad": ""+capacidad,
                    "sillaruedas": (values.cantsillaruedas==0)?"":(""+values.cantsillaruedas),
                    "activa": "1"
                  }
            ]
          },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            // console.log('usuarios', response.data);
            if(response.data.data.length>0){
                for(var i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idviajecharterfecha,
                        idviajecharterfecha:response.data.data[i].idviajecharterfecha,
                        idviajecharter:response.data.data[i].idviajecharter,
                        nombreruta:response.data.data[i].nombreruta,
                        origen:response.data.data[i].origen,
                        destino:response.data.data[i].destino,
                        horasalida:response.data.data[i].horasalida,
                        horallegada:response.data.data[i].horallegada,
                        precioruta:response.data.data[i].precioruta,
                        preciorutaninno:response.data.data[i].preciorutaninno,
                        regresa:response.data.data[i].regresa,
                        activa:response.data.data[i].activa,
                        sillaruedas:response.data.data[i].sillaruedas,
                        sobreventa:response.data.data[i].sobreventa,
                        idtipotransporte:response.data.data[i].idtipotransporte,
                        fechaviaje:response.data.data[i].fechaviaje
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        })
            .catch(error => {
                setSpinning(false);
            })
        
      };

      const onFinishFailed = (errorInfo) => {
       
      };

      const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
      };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
             <div style={{margin:0}}>
             <Form
                form={form}
                autoComplete="on"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="form_in_modal"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                    cantadultos: 1,
                    cantninnos:0,
                    cantsillaruedas:0

                }}
            >

                   <Row gutter={20}>
                   <Col span={11}>
                     <Form.Item
                                 name="fechaviaje"
                                 label="Fecha de viaje"
                                 style={{marginBottom:3}}
                                 rules={[
                                    {
                                        required: true,
                                        message: 'Por favor debe seleccionar la fecha!',
                                    },
                                ]}
                             >

                                 <DatePicker
                                     //style={{width:200}}
                                     style={{
                                         width: '100%',
                                     }}
                                     //disabledDate={disabledDate}
                                     onChange={onChange}
                                     format='DD/MM/YYYY'
                                 />

                             </Form.Item>

                             
                                   <Form.Item
                                                 name="origen"
                                                 label="Origen"
                                                 style={{marginBottom:3}}
                                             >
                                                 <Input />
                                             </Form.Item>
                             
                              
                                   <Form.Item
                                                 name="destino"
                                                 label="Destino"
                                                 style={{marginBottom:3}}
                                             >
                                                 <Input />
                                             </Form.Item>
                             
                    </Col>
                    <Col span={6} >
                       <Form.Item
                           name="cantadultos"
                           label="Adultos"
                           style={{marginBottom:3}}
                           rules={[
                            {
                                required: true,
                                message: 'Por favor debe seleccionar la cantidad!',
                            },
                        ]}
                       >
                           <InputNumber
                               min={1}
                               style={{
                                width: '100%',
                            }}
                           />
                       </Form.Item>

                       <Form.Item
                            name="cantninnos"
                            label="Niños"
                            style={{marginBottom:3}}
                        >
                            <InputNumber
                                min={0}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="cantsillaruedas"
                            label="Sillas de Rueda"
                            style={{marginBottom:3}}
                        >
                            <InputNumber
                                min={0}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>

                    </Col>
                    
                    <Col style={{marginLeft:10, marginBottom:20}}>
                        <Button type="primary" icon={<IoSearch/>} htmlType="submit">Buscar</Button>
                    </Col>

                    <Form.Item style={{marginLeft:5}} name='rutas'>

                        <Table size="small"
                                     scroll={{
                                         y: 200,
                                         x: 1800,
                                     }}
                                     pagination={{
                                         position: ["bottomCenter"],
                                         pagination: {
                                             current: 1,
                                             pageSize: 10,
                                         }}}
                                      rowSelection={{
                                          type: "radio",
                                          ...rowSelection,
                                      }}
                                      columns={columns} dataSource={data}
                                      onRow={(record) => {
                                          return {
                                              onDoubleClick: async () => {
                                                  // selectRowUpdate(record);
                                                  console.log(record);
                                              }
                                          }
                                      }}/>
                  </Form.Item>

                </Row>
            </Form>

        </div>
        </Spin>
       
    );
};

export default StepSolicViajeCharter;